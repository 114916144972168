<template>
  <div>
    <a-modal v-model:visible="visible" title="新增司机" footer="" destroyOnClose>
      <ly-formList v-if="visible" ref="formListRef" :fromList="fromList" @cancel="cancel" @getVal="getVal">
        <template #custom>
          <div class="m-t1">司机证件照片<span style="font-size:12px;color:#999">(驾驶证、货运资格证)</span></div>
          <div class=" m-t1  ">
            <ly-upload :maxNum="10" ref="upload1" text="上传照片"></ly-upload>
          </div>
        </template>
      </ly-formList>
    </a-modal>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue';
import { driverAdd } from '@/api/crmManagement/crm'
import { nextTick, onMounted, ref } from 'vue'
import { cloneDeep } from 'lodash-es';
import { useStore } from 'vuex'
import {  phoneValidate, idNumberValidate } from '@/utils/validate'
const store = useStore()
const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['success'])
const visible = ref(false)
const upload1 = ref(null)
const formListRef = ref(null)

const getVal = (e) => {
  if (upload1.value?.state.fileLoading) {
    message.warn('请等待图片上传完成')
    return
  }
  let msg = cloneDeep(e)
  if(msg.model){
    msg.brand = msg.model[1]
    msg.model = msg.model[0] 
  }
  let files = []
  upload1.value?.state.fileIds.forEach(item => {
    files.push(item.id) 
  })
  msg.fileIds = files
  msg.customerId = props.id
  formListRef.value.fileLoading = true
  driverAdd(msg).then( res => {
    if(res.code !== 10000) return
     message.success(res.msg)
      emit('success')
      cancel()
    }).finally(() => {
      if(formListRef.value){
        formListRef.value.fileLoading = false
      }
    })
}
const cancel = () => {
  visible.value = false
}
const setData = () => { 
  visible.value = true
}
defineExpose({
  setData
})

const fromList = ref([
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    placeholder: '请输入姓名',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      },
     
    ]
  },
  {
    label: '电话',
    name: 'mobile',
    type: 'input',
    placeholder: '请输入电话号码',
    value: null,
    prop: 'mobile',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入电话号码',
        trigger: 'blur'
      },
      { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '身份证号',
    name: 'idNumber',
    type: 'input',
    placeholder: '请输入身份证号',
    value: null,
    prop: 'idNumber',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入身份证号',
        trigger: 'blur'
      },
      { pattern: idNumberValidate, message: '证件号码格式不正确', trigger: 'blur' }
    ]
  },
])
</script>

<style lang="less" scoped>
</style>