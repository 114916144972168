import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '车牌号',
    value: '',
    prop: 'carNo',
    width: '12%',
  },
  {
    type: 'select',
    placeholder: '车辆类型',
    value: null,
    prop: 'truckType',
    width: '15%',
    opt:[
      {
        label: '大板车',
        value: 1
      }, {
        label: '小板车',
        value: 2
      },
    ]
  },
])
export const columns =ref([
  {
    title: '车牌号',
    dataIndex: 'carNo',
    align: 'center',
  },
  {
    title: '车辆类型',
    dataIndex: 'truckType.label',
    align: 'center',
  },
  {
    title: '车辆型号',
    dataIndex: 'model',
    align: 'center',
    slots:{ customRender:'model'}
  },
  {
    title: 'GPS类型',
    dataIndex: 'gpsType.label',
    align: 'center'
  },
  {
    title: '车位',
    dataIndex: 'place',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '车辆资料图片',
    dataIndex: 'lookImg',
    align: 'center',
    slots:{
      customRender:'lookImg'
    }
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots:{
      customRender:'add'
    }
  },
])
export const fromList = ref([
  {
    label: '车牌号',
    name: 'carNo',
    type: 'input',
    placeholder: '请输入车牌号',
    value: null,
    prop: 'carNo',
    flag:'value' ,
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入车牌号',
        trigger: 'blur'
      },
      { min: 6, max: 20, message: '车架号长度为6-20位', trigger: 'blur' }
    ]
  },
  {
    label: '车辆类型',
    name: 'truckType',
    type: 'select',
    placeholder: '请选择车辆类型',
    value: null,
    prop: 'truckType',
    width: '100%',
    flag:'lable' ,
    rules: [
      {
        required: true,
        message: '请选择车辆类型',
        trigger: 'blur',
        type:'number'
      },
    ],
    opt:[
      {
        label: '大板车',
        value: 1
      },
      {
        label: '小板车',
        value: 2
      }
    ]
  },
  {
    label: '车位数',
    name: 'place',
    type: 'inputNumber',
    placeholder: '请输入车位数',
    value: null,
    prop: 'place', 
    width: '100%',
    flag:'value',
    rules: [
      {
        required: true,
        message: '请输入车位数',
        trigger: 'blur',
        type:'number'
      },
    ]
  },
  {
    label: '车辆型号',
    name: 'model',
    type: 'cascader',
    placeholder: '请选择车辆型号',
    value: null,
    prop: 'model',
    width: '100%',
    valueType:'label',
    rules: [
      {
        required: false,
        message: '请选择车辆型号',
        trigger: 'blur',
        type:'array',
      },

    ],
    opt:[]
  },
  {
    label: 'GPS类型',
    name: 'gpsType',
    type: 'select',
    placeholder: '请选择GPS类型',
    value: null,
    prop: 'gpsType',
    width: '100%',
    flag:'lable' ,
    rules: [
      {
        required: false,
        message: '请选择GPS类型',
        trigger: 'blur',
        type:'number'
      },
    ],
    opt:[
      {
        label: '智运GPS',
        value: 1
      },
      {
        label: '超越GPS',
        value: 2
      },
      {
        label: '途强GPS',
        value: 4
      },
      {
        label: '关闭GPS',
        value: 3
      }
    ]
  },
])