import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '角色名称',
    value: '',
    prop: 'name',
    width: '10%'
  },
])
export const columns =ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '5%'
  },
  {
    title: '名称',
    dataIndex: 'name',
    align: 'center',
      width: '18%'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' },
    width: '34%'
  },

])
export const fromList = ref([
  {
    label: '角色名称',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入角色名称',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '备注',
    name: 'remark',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'remark',
    width: '100%',
    max:200
  },
 
])