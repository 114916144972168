<template>
  <div>
    <!-- 新-华月车辆物流运输合同 -->
    <a-modal :visible="visible" title="协议预览" @cancel="cancel" :width="1200" :bodyStyle="{ height: '70vh', overflow: 'auto' }">
      <div v-if="visible" id="printOrder">
        <div>
          <!-- <img style="width:204px;" v-if="form.order.carrierType?.value === 3" src='@/assets/images/log.png' /> -->
          <a-typography-title :level="2" class="contract-title">车辆物流运输合同</a-typography-title>
          <div style="text-align: end;font-size:20px;"><span style="font-weight:bold">合同编号：</span><b><span style="color:red;"> {{ form.order.orderId }}</span></b></div>
          <div style="text-align: center;margin:20px 0;font-size: 24px;color:#000"><span style="margin-right:15px;">第一部分</span>运单内容</div>
          <div class="contract-top">
            <p>起运地：<span>{{ form.order.startAddress?.details }}</span></p>
            <p>目的地：<span>{{ form.order.endAddress?.details }}</span></p>
            <p>运输日期：<span> {{ form.order.pushTime }}</span></p>
          </div>
          <div style="border-bottom:none" class="contract-mid">
            <div class="contract-mid-label">
              <a-row type="flex" align="middle" justify="space-between">
                <a-col :span="4">
                  <b>委托方(甲方)：</b>
                </a-col>
                <a-col :span="6">
                  <span>
                    {{ form.order?.customerName }}
                  </span>
                </a-col>
                <a-col :span="2">
                  <b>证件号</b>
                </a-col>
                <a-col :span="3">
                  <span>
                    {{ form.order?.certificateNumber }}
                  </span>
                </a-col>
                <a-col :span="2">
                  <b>联系电话</b>
                </a-col>
                <a-col :span="3">
                  <span>
                    {{ form.order?.customerMobile }}
                  </span>
                </a-col>
              </a-row>
            </div>
            <div class="contract-mid-label">
              <a-row type="flex" align="middle" justify="space-between">
                <a-col :span="4">
                  <b>委托方指定收车人姓名：</b>
                </a-col>
                <a-col :span="6">
                  {{ form.order.picker?.name }}
                </a-col>
                <a-col :span="2">
                  <b>证件号</b>
                </a-col>
                <a-col :span="3">
                  {{ form.order.picker?.certificateNumber }}
                </a-col>
                <a-col :span="2">
                  <b>联系电话</b>
                </a-col>
                <a-col :span="3">
                  {{ form.order.picker?.mobile }}
                </a-col>
              </a-row>
            </div>
            <div class="contract-mid-label">
              <a-row type="flex" align="middle" justify="space-between">
                <a-col :span="3">
                  <b>受托方(乙方):</b>
                </a-col>
                <a-col :span="5">
                  {{ form.order.carrierType?.label }}
                </a-col>
                <a-col :span="3.5">
                  <b>统一社会信用代码:</b>
                </a-col>
                <a-col :span="5">
                  <b v-if="form.order.carrierType?.value === 1">91510107054943960U</b>
                  <b v-if="form.order.carrierType?.value === 2"></b>
                </a-col>
                <a-col :span="1.5">
                  <b>联系电话</b>
                </a-col>
                <a-col :span="3">
                  <span>{{ form.order.salesMobile }}</span>
                </a-col>
              </a-row>
            </div>
          </div>
          <table class="table-orderDetail">
            <template v-for="(item, index) in  form.orderVehicles" :key="index">
              <tr v-if="index > 0">
                <td colspan="13" />
              </tr>
              <tr>
                <td class="label-td">车型</td>
                <td>{{ item.brand }} · {{ item.model }}</td>

                <td class="label-td">车架号/车牌号</td>
                <td>{{ item.vinNo }}</td>
                <td class="label-td">状况</td>
                <td>{{ item.status?.label }}</td>
                <td rowspan="4">
                  合计：
                  <a-typography-text type="danger"> ￥ {{ item.totalFee }} </a-typography-text>
                </td>
              </tr>
              <tr>
                <td class="label-td">服务费</td>
                <td>
                  <a-typography-text type="danger"> ￥ {{ item.freight }} </a-typography-text>
                </td>
                <td class="label-td">保额（万元）</td>
                <td>{{ item.insuranceFee }}</td>
                <td class="label-td">保险费</td>
                <td>
                  <a-typography-text type="danger"> ￥ {{ item.insurancePremium }} </a-typography-text>
                </td>
              </tr>
              <tr>
                <td class="label-td">取车费</td>
                <td>
                  <template v-if="item.isPlaceIn">
                    <template v-if="item.isPlaceIn.value">
                      <a-typography-text type="danger"> ￥ {{ item.placeInFee }} </a-typography-text>
                    </template>
                    <template v-else>
                      {{ item.isPlaceIn?.label }}
                    </template>
                  </template>
                </td>
                <td class="label-td">取车地址</td>
                <td class="left-td" colspan="3">
                  <template v-if="item.isPlaceIn && item.isPlaceIn?.value">
                    {{ item.placeInAddress }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="label-td">送车费</td>
                <td>
                  <template v-if="item.isTakeOut?.value">
                    <a-typography-text type="danger"> ￥ {{ item.takeOutFee }} </a-typography-text>
                  </template>
                  <template v-else>{{ item.isTakeOut?.label }}</template>
                </td>
                <td class="label-td">送车地址</td>
                <td colspan="3" class="left-td">
                  <template v-if="item.isTakeOut && item.isTakeOut?.value">
                    {{ item.takeOutAddress }}
                  </template>
                </td>
              </tr>
            </template>
          </table>
          <div style="border-top:none" class="contract-mid">
            <div class="contract-mid-value">
              <p><b>车辆其他协商事宜：{{ form.order.remark }}</b>{{ form.order.remark !== '' ? '，' : '' }}车辆外观以照片为准，车内禁止放贵重物品</p>
            </div>
            <div class="contract-mid-label">
              <a-row type="flex" align="middle" justify="space-around">
                <a-col :span="3">
                  <b>费用总计</b>
                </a-col>
                <a-col :span="3">
                  <a-typography-text type="danger">{{ totalFee }} CNY</a-typography-text>
                </a-col>
                <a-col :span="3">
                  <b>大写</b>
                </a-col>
                <a-col :span="3">
                  <a-typography-text type="danger">{{ totalFeeChar }}</a-typography-text>
                </a-col>
                <a-col :span="3">
                  <b>结算方式</b>
                </a-col>
                <a-col :span="3">
                  <template v-if="form.order.orderSettlement?.value === 3">
                    预付 <a-typography-text type="danger"> ￥ {{ form.order.advanceCharge }}</a-typography-text>
                    到收 <a-typography-text type="danger"> ￥ {{ form.order.paymentArrival }}</a-typography-text>
                  </template>
                  <template v-else>
                    {{ form.order.orderSettlement?.label }}
                  </template>
                </a-col>
              </a-row>
            </div>
            <!-- <div class="contract-mid-text" style="padding:10px">
            <span>备注：</span>
            <div style="display: flex;justify-content: space-around;">
              <div>开票类型：<span style="margin:0 40px 0 20px">是</span>否</div>
              <div>税率：______%</div>
            </div>
          </div> -->
            <div class="contract-mid-motion">
              <span>温馨提示：</span>
              <p>1、请签字前一定确认好车辆价值并足额投保；</p>
              <p>2、车辆托运服务中的验车环节仅对车辆外观直接可视部分进行查验，不涉及车辆内部构造以及性能的查验，同时也不对车辆内部构造零配件以及性能承担任何担保和赔偿责任；</p>
              <p>3、签字前请务必阅读该合同各条款。</p>
              <div class="contract-mid-motion-sign">
                <div>
                  <b>甲方（签字）：</b>
                </div>
                <div>
                  <b> 乙方（盖章）：</b>
                </div>
              </div>
            </div>
          </div>
          <!-- v-if="form.order.orderMethod === 1" -->
          <img class="contract-seal" v-if="chapter" :src='chapter' />
          <div class="contract-content">
            <h1 class="item" style="font-size:24px"><span style="margin-right:15px">第二部分</span> 合同条款</h1>
            <template v-if="valueHtml">
              <div v-html="valueHtml"></div>
            </template>
            <template v-else>
               <span style="font-size:30px">暂无第二部分合同内容，请到系统管理中的承运主体处进行配置</span>
            </template>
            <div class="item" style="margin-top:25px">
              <span style="color:#000;font-weight:bold">特别提示：为充分保证您的权益，请您仔细阅读本合同。本合同系运单内容的有效组成部分，甲方签署运单或通过电子下单方式下单的视为已经仔细阅读本合同内容并无任何异议。</span>
            </div>
            <div class="item" style="margin-bottom: 20px;">(以下无正文)</div>
            <div class="item" style="padding-right:150px">
              <div style="display: flex;justify-content: space-between;">
                <span>甲方（签字/盖章）</span>
                <div style="position: relative;">
                  <span>乙方（盖章）</span>
                  <img class="bottom-img" v-if="chapter" :src='chapter' /> 
                </div>
              </div>
              <p style="display: flex;justify-content: space-between;">
                <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
                <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
              </p>
            </div>
            <div style="height:50px"></div>
          </div>
        </div>
      </div>
      <template #footer>
        <a-space>
          <a-input addon-before="如果合同文字被分割，输入0-50可微调，以下载为准" v-model:value="checkNum" type='number'></a-input>
          <a-button @click="pdfDownload">下载</a-button>
          <a-button type="primary" v-print="'#printOrder'">打印</a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { jsPDF as JsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { bodyDetail } from '@/api/system/carrierSubject'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  totalFee: {
    type: Number,
    default: 0,
  },
  totalFeeChar: {
    type: String,
    default: '',
  },
  form: {
    type: Object,
    default: {},
  }
})
const emit = defineEmits(['update:visible'])
const valueHtml = ref('')
const chapter = ref('')
const cancel = () => {
  emit('update:visible', false)
}
const checkNum = ref(0)
const pdfDownload = async () => {
  // await printPdf()
  await html2canvas(document.querySelector('#printOrder')).then(canvas => {
    const contentWidth = canvas.width
    const contentHeight = canvas.height
    // 一页pdf显示html页面生成的canvas高度;
    const pageHeight = contentWidth / 592.28 * 841.89
    // 未生成pdf的html页面高度
    let leftHeight = contentHeight
    // 页面偏移
    let position = 20
    // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    const imgWidth = 555.28
    const imgHeight = 552.28 / contentWidth * contentHeight
    const pageData = canvas.toDataURL('image/jpeg', 1.0)
    const pdf = new JsPDF('', 'pt', 'a4')
    // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    // 当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight - checkNum.value)
        leftHeight -= pageHeight
        position -= 841.89
        // 避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage()
        }
      }
    }
    pdf.save(`车辆运输协议-${props.form.order.orderId}.pdf`)
  })
}
const reqBodyDetail = () => {
  if(!props.form.order.carrierBodyId) return
  bodyDetail(
  props.form.order.carrierBodyId
  ).then( async res => {
    if(res.code !== 10000) return
     valueHtml.value = res.data.contractContent
     chapter.value =  res.data.contractSealEncode ? 'data:image/png;base64,' + res.data.contractSealEncode : ''
  })
}

onMounted(() => {
  reqBodyDetail()
 
})
</script>

<style lang="less" scoped>
.ant-typography {
  margin: 0;
}

.suspension-button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 16px;
}

.table-orderDetail {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
    white-space: nowrap;
  }

  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 300px;
  }

  .left-td {
    text-align: left;
  }

  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

.vehicle-div {
  background: #eee;
}

.contract {
  &-title {
    text-align: center;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-mid {
    border: 1px solid #000;

    &-label {
      border-bottom: 1px solid #000;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &-value {
      text-indent: 1em;
      border-bottom: 1px solid #000;

      p {
        padding-top: 5px;
      }
    }

    &-text {
      border-bottom: 1px solid #000;

      p {
        text-indent: 2em;
      }
    }

    &-motion {
      color: red;

      p {
        margin: 0;
      }

      &-sign {
        width: 50%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        b {
          flex: 1;
          color: #000;

          span {
            display: inline-block;
            font-weight: normal;
            margin-left: 50px;
          }
        }
      }
    }
  }

  &-head {
    &-label {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-align: left;
      text-indent: 1em;
    }
  }

  &-content {
    font-size: 16px;
    text-indent: 2em;

    p {
      margin-bottom: 15px;
    }

    h1 {
      text-align: center;
    }

    &-sort {
      font-size: 18px;
      font-weight: 600;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      text-indent: 2em;
    }

    // &-label-title{
    //   font-size: 18px;
    //   font-weight: bold;
    // }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-indent: 1em;
      text-align: left;
      height: 24px;
    }

    &-right {
      text-align: right;
      padding-right: 2em;
    }
  }

  &-footer {
    text-indent: 2em;
    margin-bottom: 8px;

    &-label {
      font-size: 16px;
      font-weight: 600;
    }

    &-value {
      font-size: 16px;
      text-indent: 1em;
    }
  }

  &-seal {
    width: 230px;
    margin-top: -120px;
    margin-left: 65%;
    filter: opacity(0.7);
  }
}

.bottom-img {
  position: absolute;
  width: 230px;
  top: -120px;
  left: 0%;
  filter: opacity(0.7);
}
</style>