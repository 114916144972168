import { ref } from 'vue'
import enumAll from '@/store/modules/enumAll.js'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '客户',
    value: null,
    prop: 'customerName',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'sourceMan', 
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '录单员',
    value: null,
    prop: 'salesman',
      width: '10%'
  },
  {
    type: 'select',
    placeholder: '订单状态',
    value: null,
    prop: 'orderStatus',
    width: '10%',
    opt:[
      {
        value: 0,
        label: '保存'
      },
      {
        value: 1,
        label: '已推送'
      },
      {
        value: 2,
        label: '运输中'
      },
      {
        value: 9,
        label: '已完成'
      },
    ]
  },
  {
    type: 'select',
    placeholder: '订单来源',
    value: null,
    width: '10%',
    prop: 'orderSource',
    opt:[
      {
      label: '华月',
      value: 9
     },
     {
      label: '内部',
      value: 1
     },
    ]
  },
  {
    type: 'select',
    placeholder: '结算方式',
    value: null,
    prop: 'orderSettlement',
    width: '10%',
    opt:[
      { label: '货到付款', value: 1 },
      { label: '起运地付清', value: 2 },
      { label: '预付X到付X', value: 3 },
      { label: '月结', value: 4 },
      { label: '记账', value: 5 }
    ],
  },
  {
    type: 'select',
    placeholder: '结算状态',
    value: null,
    prop: 'settlementStatus',
    width: '10%',
    opt:[
      { label: "未结算", value: 0 },
      { label: "部分结算", value: 1 },
      { label: "已结算", value: 2 }
    ],
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '起运地',
    value: null,
    prop: 'startAddress',
    width: '10%',
    prefix:'始'
  },
  {
    type: 'input',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    width: '10%',
    prefix:'终'
  },
  {
    type: 'selectTime',
    placeholder: '推送时间起',
    value: null,
    prop: 'pushStartTime',
    width: '10%',
  },
  {
    type: 'selectTime',
    placeholder: '推送时间终',
    value: null,
    prop: 'pushEndTime',
    width: '10%',
  },

])
export const columns =ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '3%'
  },
  {
    title: '订单号',
    dataIndex: 'id',
    slots: { customRender: 'id' },
    align: 'center'
  },
  {
    title: '订单来源',
    dataIndex: 'orderSource.label',
    align: 'center'
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    align: 'center',
    slots:{ customRender:'orderStatus' }
  },
  {
    title: '合同金额',
    dataIndex: 'contractAmt',
    align: 'center'
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center'
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    slots:{ customRender:'startAddress' },
    align: 'center'
  },
  {
    title: '订单车辆',
    dataIndex: 'vinNo',
    align: 'center',
    slots:{ customRender:'vinNo'}
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center'
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center'
  },
  {
    title: '特殊需求',
    dataIndex: 'isInvoice',
    slots: { customRender: 'isInvoice' },
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' },
    width: '12%'
  },

])
export const fromList = ref([
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '电话',
    name: 'mobile',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'mobile',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入电话',
        trigger: 'blur'
      },
      {
        max:11,
        min:11,
        message: '电话格式不正确',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '身份证号码',
    name: 'idNumber',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'idNumber',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入身份证号码',
        trigger: 'blur'
      },
      {
        max:18,
        min:18,
        message: '身份证号码格式不正确',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '账号',
    name: 'no',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'no',
    width: '100%',
    tips:'（数字或字母）',
    rules: [
      {
        required: true,
        message: '请输入账号',
        trigger: 'blur'
      },
      {
        pattern: /\s*\S+?/,
        message: '账号格式不正确',
        trigger: 'blur'
      }
    ]
  },
  {
    label: '所属机构',
    name: 'orgId',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'orgId',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择所属机构',
        trigger: 'change',
      },
    ],
    opt:[]
  },
  {
    label: '职务',
    name: 'positionType',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'positionType',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择职务',
        trigger: 'change',
        type:'number',
      },
    ],
    opt:[]
  },
  {
    label: '入职时间',
    name: 'entryDate',
    type: 'selectTime',
    placeholder: '请选择',
    value: null,
    prop: 'entryDate',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择时间',
        trigger: 'change',
        type:  'object',
      },
    ]
  },
])

export const vehColumns =ref([
  {
    title: '车型',
    dataIndex: 'brand',
    slots:{ customRender:'brand' },
    align: 'center'
  },
  {
    title: '车牌',
    dataIndex: 'vinNo',
    align: 'center'
  },
  {
    title: '车辆状态',
    dataIndex: 'vehicleTransStatus.label',
    align: 'center'
  },
  {
    title: '运费',
    dataIndex: 'freight',
    align: 'center'
  },
  {
    title: '取车费',
    dataIndex: 'placeInFee',
    align: 'center'
  },
  {
    title: '送车费 ',
    dataIndex: 'takeOutFee',
    align: 'center'
  },
  {
    title: '附加费',
    dataIndex: 'additionalFeeTotal',
    align: 'center'
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'center'
  },
  {
    title: '毛利年月',
    dataIndex: 'profitMonth',
    slots:{ customRender:'profitMonth' },
    align: 'center'
  },

  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center'
  },
])
export const setColumns =ref([
  {
    title: '结算类型',
    dataIndex: 'payType.label',
    align: 'center'
  },
  {
    title: '结算金额',
    dataIndex: 'settableTotalAmt',
    align: 'center'
  },
  {
    title: '支付金额',
    dataIndex: 'totalAmount',
    align: 'center'
  },
  {
    title: '支付类型',
    dataIndex: 'businessType.label',
    align: 'center'
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '支付流水',
    dataIndex: 'orderCode',
    align: 'center'
  },

])
export const transportColumns =ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '4%'
  },
  {
    title: '运输车牌',
    dataIndex: 'carNo',
    align: 'center',
  },
  {
    title: '运输费用',
    dataIndex: 'transportFee',
    align: 'center',
    slots: { customRender: 'transportFee' },
  },
  {
    title: '运输状态',
    dataIndex: 'truckStatus.label',
    align: 'center',
  },
  {
    title: '线路',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center',
  },
  {
    title: '装车时间',
    dataIndex: 'loadingTime',
    align: 'center',
  },
  {
    title: '装车人',
    dataIndex: 'loadingOperator',
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'departureTime',
    align: 'center',
  },
  {
    title: '发车人',
    dataIndex: 'departure',
    align: 'center',
  },
  {
    title: '卸车地',
    dataIndex: 'endAddress',
    align: 'center',
  },
  {
    title: '卸车时间',
    dataIndex: 'unloadingTime',
    align: 'center',
  },
  {
    title: '卸车人',
    dataIndex: 'unloadingOperator',
    align: 'center',
  },
])
