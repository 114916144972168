<template>
<div>
  <ly-table :columns="columns" ref="tableRef" size="small" rowKey="id" :data="data" :loading="loading" :pagination="pagination" @pageChange="pageChange">
        <template #add="{ record }">
          <a v-if="record.auditStatus.value === 1" @click="cancel(record)">取消</a>
          <span v-else>已取消</span>
        </template>
        <template #fileId="{ record }">
          <a v-if="record.fileId" @click="lookImg(record)">查看</a>
        </template>
      </ly-table>
         <!-- 取消 -->
    <a-modal v-model:visible="visibleCancel" title="取消原因" footer="" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="fromList" @cancel="visibleCancel = false" @getVal="submit"></ly-formList>
    </a-modal>
    <!-- 自定义图片预览 -->
    <lyPictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></lyPictureViewer>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns, fromList } from './additionalFeeList.js'
import { orderVehicleFeePage, cancelFeeException } from '@/api/finance/income.js'
import { message } from 'ant-design-vue';
import { transitionTimeYMD } from '@/utils/util'
import { cloneDeep } from 'lodash-es';
const props = defineProps({
  orderVehicleId: {
    type: String,
    default: ''
  }
})

const formListRef = ref(null)
const searchMsg = ref({})
const lookImgID = ref([])
const visibleImgPic = ref(false)
const loading = ref(false)
const data = ref([])
const visibleCancel = ref(false)
const cancelID = ref('')
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqOrderVehicleFeePage()
}

// 取消
const submit = (e) => {
  formListRef.value.fileLoading = true
  e.id = cancelID.value
  cancelFeeException(e).then( res => {
  if(res.code !== 10000) return
  reqOrderVehicleFeePage()
  message.success(res.msg)
  visibleCancel.value = false
  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
const cancel = (record) => {
  visibleCancel.value = true
  cancelID.value = record.id
}
const lookImg = (record) => {
  lookImgID.value = [record.fileId]
  visibleImgPic.value = true
}


const reqOrderVehicleFeePage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
    orderVehicleId:props.orderVehicleId
  }
  Object.assign(msg, searchMsg.value)
  orderVehicleFeePage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}
defineExpose({
  reqOrderVehicleFeePage
})
onMounted(() => {
  reqOrderVehicleFeePage()
})

</script>

<style lang="less" scoped>

</style>