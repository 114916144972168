import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '机构名称',
    value: '',
    prop: 'orgName',
    width: '10%'
  }
])
export const columns =ref([
 
  {
    title: '机构名称',
    dataIndex: 'name',
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' },
     width: '18%'
  },

])
export const fromList = ref([
  {
    label: '机构名称',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入机构名称',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '上级机构',
    name: 'parentOrgId',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'parentOrgId',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入机构名称',
        trigger: 'blur'
      },
    ],
    opt:[]
  },
])