<template>
  <div>
    <a-upload multiple :class="previewUrl?'avatar-uploader':''" accept="image/*" :show-upload-list="false" name="avatar" list-type="picture-card" :before-upload="beforeUpload">
      <img v-if="previewUrl" :class="type === 1 ? 'imageUrl1' : 'imageUrl2'" :src="previewUrl" alt="avatar" />
      <div v-else>
        <loading-outlined v-if="state.fileLoading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">上传照片</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { useStore } from 'vuex';
import { saveLogo, uploadLogo as upload } from '@/api/system/logo'

const store = useStore()
const props = defineProps({
  type: {
    type: Number,
    default: 0
  },
  logo: {
    type: Object,
    default: {}
  }

})
const emit = defineEmits(['fileStatus'])
const previewUrl = ref("")
const previewVisible = ref(false)
const state = reactive({
  fileList: {},
  fileLoading: false,
  fileIds: {}
})

const beforeUpload = (file) => {
  state.fileList = file
  handleChange1()
  return false
}

// 图片上传
const handleChange1 = () => {
  if (!state.fileLoading) {
    previewUrl.value = ''
    state.fileLoading = true
    updateBatch(state.fileList)
  }
}
// 图片上传
const updateBatch = (imageList) => {
  const formData = new FormData()
  formData.append('file', imageList)
  upload(1, formData).then((res) => {
    if (res.code === 10000) {
      reqSaveLogo(props.type, res.data)
    }
  })
}
const reqSaveLogo = (type, data) => {
  let msg = {}
  type === 1 ? msg.expandLogoId = data?.id : msg.shrinkLogoId = data?.id
  saveLogo(msg).then(res => {
    if (res.code !== 10000) return
    store.dispatch('getLogos');
    setTimeout(() => {
      if (props.type === 1) {
        previewUrl.value = store.getters.logos.expandLogo.url
      } else {
        previewUrl.value = store.getters.logos.shrinkLogo.url
      }
      message.success('图片上传成功')
      state.fileLoading = false
    }, 1000)
  })
}
onMounted(() => {

    if (store.getters.logos.expandLogo && props.type === 1) {
      previewUrl.value = store.getters.logos.expandLogo?.url
    }
    if (store.getters.logos.shrinkLogo && props.type === 2) {
      previewUrl.value = store.getters.logos.shrinkLogo?.url
    }


})

</script>

<style lang="less" scoped>
.avatar-uploader {
  :deep(.ant-upload) {
    background: #001529;
  }
}
.imageUrl1 {
  width: 200px;
  height: 50px;
}
.imageUrl2 {
  width: 60px;
  height: 60px;
}
</style>