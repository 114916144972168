import { ref } from 'vue'

export const columns =ref([
  {
    title: '主体名称',
    dataIndex: 'name',
    align: 'center',
      width: '18%'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'status',
    slots:{
      customRender: 'status'
    },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' },
  },

])
export const fromList = ref([
  {
    label: '主体名称',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入主体名称',
        trigger: 'blur'
      },
    ]
  },
])