<style lang="less" scoped>
</style>
<template>
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <!-- <div>已选择角色</div>
    <div>
      <a-tag v-for="item in listDataRole" :key="item" color="pink">{{item.name}}</a-tag>
    </div> -->
    <div>
      <a-table :columns="[sortColumn,...columns]" :pagination="pagination" @change="pageChange" :row-key="record => record.id" :data-source="listData" :loading="loading">
        <template #sort="{ index }">
          {{index+1}}
        </template>

        <template #operation="{ record }">
          <span>
            <a-popconfirm :title="tipsTitle" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" @cancel="cancel">
              <a-switch size="small" :checkedValue="1" :unCheckedValue="0" @change="swChange($event,record)" :checked="record.selected" />
            </a-popconfirm>

          </span>
        </template>
      </a-table>
    </div>
  </a-drawer>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { employeeRole, rolePage, employeeDataRole } from '@/api/system/employee'
export default {
  computed: {
  },

  setup () {
    const state = reactive({
      tipsTitle: '',
      listData: [],
      listDataRole: [],
      empId: '',
      form: {},
      authValue: '',
      visible: false,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 400,
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const pageChange = (e) => {
      state.pagination = e
      loadData(state.empId)
    }
    const swChange = (e, v) => {
      if (v.selected === 0) {
        state.tipsTitle = '确定要打开吗？'
      } else {
        state.tipsTitle = '确定要关闭吗？'
      }
    }
    const loadData = async (id) => {
      state.empId = id
      state.loading = true
      rolePage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
      }).then((res) => {
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
        employeeRole(state.empId).then((res) => {
          state.listDataRole = res.data
          state.listData.forEach(d => {
            d.selected = 0
            state.listDataRole.forEach(r => {
              if (r.roleId === d.id) {
                d.selected = 1
              }
            })
          })
        }).finally(() => {
          state.loading = false
        })
      })

    }
    const onClose = (recode) => {
      state.visible = recode
    }
    const confirm = (v) => {
      state.form = {
        roleId: v.id,
        empId: state.empId,
      }
      employeeDataRole(state.empId, state.form).then(res => {
        if (res.code !== 10000) return
        v.selected = v.selected == 0 ? 1 : 0
        message.success('操作成功')
      })
    }

    const changeAuth = (record) => {
      state.form = {
        roleId: record.id,
        empId: state.empId
      }
      // 保存单次权限关系
    }
    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      changeAuth,
      swChange,
      confirm,
      pageChange
    }
  }
}
</script>
 