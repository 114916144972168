import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 我待办的流程
export function flowPageTodo(params) {
  return postBodyRequest('/cs/flow/instance/page/todo', params)
}
// 我申请的流程
export function flowPageSelf(params) {
  return postBodyRequest('/cs/flow/instance/page/self', params)
}
// 流程实例详情
export function instanceInfo(flowInstanceId) {
  return postBodyRequest(`/cs/flow/instance/${flowInstanceId}`)
}

// 抄送
export function copyPeople(params) {
  return postBodyRequest(`/cs/flow/instance/copy/people`,params)
}
// 执行任务
export function execute(params) {
  return postBodyRequest(`/cs/flow/instance/execute`,params)
}
// 我已办的流程
export function pageDone(params) {
  return postBodyRequest(`/cs/flow/instance/page/done`,params)
}
// 抄送列表
export function copyPage(params) {
  return postBodyRequest(`/cs/flow/instance/copy/people/page`,params)
}
// 添加当前任务备注
export function taskRemark(params) {
  return postBodyRequest(`/cs/flow/instance/task/remark`,params)
}