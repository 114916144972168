import { ref } from 'vue'
import { expenseOptions } from "@/utils/enum.js"; 
import dict from '@/config/dict.json'
import enumAll from '@/store/modules/enumAll'
export const iptData = ref([
  {
    type: 'selectTime',
    placeholder: '下单时间起',
    value: null,
    prop: 'orderTimeStart',
   width: '13%'
  },
  {
    type: 'selectTime',
    placeholder: '下单时间止',
    value: null,
    prop: 'orderTimeEnd',
    width: '13%'
  },
  {
    type: 'address',
    placeholder: '起始地',
    value: null,
    prop: 'startAddress',
    changeOn:true,
    width: '13%'
  },
  {
    type: 'address',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    changeOn:true,
    width: '13%'
  },
  {
    type: 'select',
    placeholder: '运输状态',
    value: null,
    prop: 'orderVehicleStatus',
    width: '13%',
    opt:[
      { label: '全部', value: '' },
      { label: '未安排', value: 1 },
      { label: '已装车', value: 2 },
      { label: '运输中', value: 3 },
      { label: '中转待派', value: 4 },
      { label: '待送车', value: 5 },
      { label: '完成', value: 9 },
    ]
  },
  {
    type: 'select',
    placeholder: '结算方式',
    value: null,
    prop: 'orderSettlement',
    width: '13%',
    opt:[
      { label: '货到付款', value: 1 },
    { label: '起运地付清', value: 2 },
    { label: '预付X到付X', value: 3 },
    { label: '月结', value: 4 },
    { label: '记账', value: 5 }
    ]
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'sourceMan',
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '录单员',
    value: null,
    prop: 'salesman',
      width: '13%'
  },
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null, 
    prop: 'vinNo',
    width: '13%',
  },
  {
    type: 'select',
    placeholder: '是否交车',
    value: null, 
    prop: 'isDelivery',
    width: '13%',
    opt:dict.whetherAll
  },
  {
    type: 'select',
    placeholder: '订单来源',
    value: null,
    prop: 'orderSource',
    width: '13%',
    opt:[
      {
      label: '华月',
      value: 9
     },
     {
      label: '内部',
      value: 1
     },
    ]
  },
])

export const columns =ref([

  {
    title: '车牌车型',
    dataIndex: 'brand',
    align: 'center',
    slots:{
     customRender:'brand'
    },
    width:'150px'
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    width:'10%',
    align: 'center',
    slots:{
      customRender:'startAddress'
    }
  },
  {
    title: '运输状态',
    dataIndex: 'vehicleTransStatus',
    slots:{customRender:'vehicleTransStatus' },
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center'
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
    width:'9%'
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    width:'6%'
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center',
  },
  {
    title: '运费',
    dataIndex: 'freight',
    align: 'center'
  },
  {
    title: '取车费',
    dataIndex: 'placeInFee',
    align: 'center'
  },
  {
    title: '送车费',
    dataIndex: 'takeOutFee',
    align: 'center'
  },
  {
    title: '保险费',
    dataIndex: 'insuranceCost',
    align: 'center'
  },
  {
    title: '到收金额',
    dataIndex: 'paidAmt',
    align: 'center'
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'center'
  },
  {
    title: '合同已结算金额',
    dataIndex: 'settledAmt',
    align: 'center'
  },
  {
    title: '合同结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center'
  },
  {
    title: '运输成本',
    dataIndex: 'freightCost',
    align: 'center'
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'center'
  },
  {
    title: '是否交车',
    dataIndex: 'isDelivery.label',
    align: 'center'
  },
  {
    title: ' 操作',
    dataIndex: 'addContent',
    slots:{ customRender:'addContent'},
    fixed: 'right',
    width:'200px',
    align: 'center'
  },
])
export const vehFormList = ref([
  {
    label: '交车说明',
    name: 'remark',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'remark',
    width: '30%',
    rows:2,
    rules: [
      {
        required: false,
        message: '请选择',
        trigger: 'blur'
      },
    ],
  },
])
export const surFormList = ref([
  {
    label: '金额',
    name: 'amount',
    type: 'inputNumber',
    placeholder: '请输入',
    value: null,
    prop: 'amount',
    width: '40%',
    rules: [
      {
        required: true,
        message: '请输入',
        trigger: 'blur',
         type:'number'
      },
    ],
  },
  {
    label: '挂账对象',
    name: 'carrierId',
    type: 'selectSearch',
    placeholder: '请输入',
    value: null,
    prop: 'carrierId',
    width: '40%',
    rules: [
      {
        required: true,
        message: '请搜索选择',
        trigger: 'blur'
      },
    ],
  },
  {
    label: '挂账司机',
    name: 'driverId',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'driverId',
    width: '40%',
    opt:[]
  },
  {
    label: '费用类型',
    name: 'exceptionType',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'exceptionType',
    width: '40%',
    rules: [
      {
        required: false,
        message: '请选择',
        trigger: 'blur',
        type:'number'
      },
    ],
    opt:expenseOptions
  },
  {
    label: '备注',
    name: 'exceptionExplain',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'exceptionExplain',
    width: '30%',
    rows:2,
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ],
  },
  {
    label: '费用凭证',
    name: 'fileId',
    type: 'upload',
    value: null,
    prop: 'fileId',
    width: '40%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ],
  },
])