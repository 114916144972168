<template>
  <div>
    <div class="navBox flex al-center pos-rel">
    <div style="width:90px;">
      <div class="icon">
        {{ msgInfo.customerType?.label }}
      </div>
    </div>
      <div class="m-l2" style="width:100%">
        <div class="name"><span class="color-B">[ {{ msgInfo.businessType?.label }}]</span>{{ msgInfo.name }}</div>
        <div class="flex m-t1 changeStyle">
          <div class="m-r3">
            负责人：{{ msgInfo.charge }} {{ msgInfo.chargeMobile }}
          </div>
          <div class="m-r3">
            结算方式：{{ msgInfo.settlementMode.label }}
          </div>
          <div class="m-r3">
            创建人：{{ msgInfo.creator }} {{ msgInfo.createTime }}
          </div>
          <div class="m-r3" >
            <span >身份证：{{msgInfo.certificateNumber}}</span>
           <a v-if="msgInfo?.fileIds.length>0" class="m-l1" @click="lookImg(msgInfo.fileIds)">照片</a>
          </div>
          <div class="address" v-if="msgInfo.customerAddress">
            <div v-if="(msgInfo.customerAddress.length +msgInfo.customerAddressDetail.length) >25">
              <a-popover>
                <template #content>
                  {{ msgInfo.customerAddress }}{{  msgInfo.customerAddressDetail }}
                </template>
                <div class="text">
                  常用地址：{{ msgInfo.customerAddress }}{{  msgInfo.customerAddressDetail }}
                </div>
              </a-popover>
            </div>
            <div v-else>
              常用地址：{{ msgInfo.customerAddress }}{{  msgInfo.customerAddressDetail }}
            </div>
          </div>
        </div>
      </div>
      <div class="pos-abs" style="right:20px">
        <a-button type="primary" size="small" @click="edit" ghost>编辑</a-button>
      </div>
      <div class="pos-abs cur-p" style="top:4px;right:25px">
        <CloseOutlined @click="close" />
      </div>
    </div>
    <div style="height:14px;background:#f2f2f291"></div>
    <div style="padding:20px">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="车辆列表">
          <VehicleList @lookImg="lookImg" v-if="activeKey == 1" :id="msgInfo.id"></VehicleList>
        </a-tab-pane>
        <a-tab-pane key="2" tab="司机列表">
          <DriverList v-if="activeKey == 2"   @lookImg="lookImg" :id="msgInfo.id"></DriverList>
        </a-tab-pane>
        <a-tab-pane key="3" tab="承运对接人">
          <CarrierLiaison v-if="activeKey == 3"  @lookImg="lookImg"  :id="msgInfo.id" ></CarrierLiaison>
        </a-tab-pane>
        <a-tab-pane key="4" tab="业务对接人">
          <BusinessLiaison v-if="activeKey == 4"   :id="msgInfo.id"></BusinessLiaison>
        </a-tab-pane>
      </a-tabs>
    </div>
        <!-- 自定义图片预览 -->
      <lyPictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></lyPictureViewer>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import VehicleList from './conponents/vehicleList.vue';
import BusinessLiaison from './conponents/businessLiaison.vue';
import DriverList from './conponents/driverList.vue';
import CarrierLiaison from './conponents/carrierLiaison.vue'
import { CloseOutlined } from '@ant-design/icons-vue';
const emit = defineEmits(['edit', 'close'])
const props = defineProps({
  msgInfo: {
    type: Object,
    default: {}
  }
})
const lookImgID = ref([])
const visibleImgPic = ref(false)
const lookImg = (fileIds) => {
    lookImgID.value = fileIds
  visibleImgPic.value = true
 
}
const activeKey = ref('1')
const edit = () => {
  emit('edit', props.msgInfo.id)
}
const close = () => {
  emit('close')
}

</script>

<style lang="less" scoped>
.navBox {
  width: 100%;
  height: 120px;
  padding: 20px;

  .name {
    color: #191919;
    font-size: 20px;
    font-weight: bold;
  }

  .icon {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #396afc;
    font-size: 22px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1800px) {
  .changeStyle {
    flex-wrap: wrap;
    .address {
  width: 100%;
}
  }

}

.address {
  width: 30%;
  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>