<template>
  <div>
    <a-form ref="formRef" :model="form" :rules="rules" :labelCol="{flex:'100px'}" scrollToFirstError>
      <a-form-item :wrapperCol="{flex:'auto'}" label="收款总金额">
        <a-typography-text type="danger">{{msgInfo.amt}} 元 </a-typography-text>
        <a-typography-text type="secondary">{{amountUppercase(msgInfo.amt)}}</a-typography-text>
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="付款人" name="payer">
        <a-input v-model:value="form.payer" />
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="付款账户" name="payWay">
        <a-select v-model:value="form.payWay">
            <a-select-option v-for="item in dataList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="付款时间" name="paymentTime">
        <a-date-picker v-model:value="form.paymentTime" style="width:100%;" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="付款截图">
        <a-upload :file-list="imgFileList" accept="image/*" list-type="picture" :remove="handleRemove" @preview="handlePreview" :before-upload="beforeUpload" >
          <a-button :disabled="imgFileList.length > 0">
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="备注">
        <a-textarea v-model:value="form.remark" />
      </a-form-item>
      <a-form-item :wrapperCol="{flex:'auto'}" label="上传其他图片">
        <ly-upload  @fileStatus="status" ref="uploadRef"></ly-upload>
      </a-form-item>
    </a-form>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览图片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { CameraOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { amountUppercase } from '@/utils/util'
import { fileUpload as upload } from '@/api/utils'
import {  collectionList } from '@/api/system/logo'
const props = defineProps({
  msgInfo: {
    type: Object,
    default: {}
  },
  cardAmt:{
    type: Number,
    default: 0
  },
  oilAmt:{
    type: Number,
    default: 0
  }

});
const emit = defineEmits(['fileStatus'])
const dataList = ref([])
const formRef = ref(null)
const imgFileList = ref([])
const previewImage = ref('')
const previewVisible = ref(false)
const loading = ref(false)
const uploadRef = ref(null)
const form = ref({
  customerName: '',
  payer: '',
  paymentfileId: '',
  paymentTime: '',
  remark: ''
})
const rules = ref({
  payer: { required: true, message: '请填写付款人', trigger: 'blur' },
  payWay: { required: true, message: '请填写付款账户', trigger: 'change' },
  paymentTime: { required: true, message: '请选择付款时间', trigger: 'change' }
})

const handleRemove = file => {
 imgFileList.value = []
}
const handlePreview =  file => {
  previewImage.value = file.url
  previewVisible.value = true
}

const beforeUpload = (file) => {
    handleChange(file)
    return false
}
const status = (e) => {
  loading.value = e
  emit('fileStatus', loading.value)
}
const handleChange = (file) => {
  loading.value = true
  emit('fileStatus', loading.value)
  const formData = new FormData()
    formData.append('file',file)
    upload(formData).then((res) => {
      if (res.code === 10000) {
        imgFileList.value =[res.data]
        message.success('上传成功') 
      } 
    }).finally(() => {
      loading.value = false
      emit('fileStatus', loading.value)
    })
}
onMounted(() => {
  collectionList({}).then( res => {
    if(res.code !== 10000) return
    dataList.value = res.data
  })
})
defineExpose({
  form,
  formRef,
  uploadRef,
  imgFileList
})
</script>

<style lang="less" scoped>
</style>