<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
          <span style="margin-right: 3px;">+</span>新增角色
        </ly-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" rowKey="id" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #sort="{index}">
          {{ index+1 }}
        </template>
        <template #add="{ record }">
          <a @click="grant(record,2)">APP授权</a>
          <a class="m-l3" @click="grant(record,1)">PC授权</a>
          <a class="m-l3" @click="edit(record)">编辑</a>
          <!-- <span class="m-l3 color-R" @click="delAdd(record)">删除</span> -->
        </template>
      </ly-table>
    </div>
    <ly-modal v-model:visible="visible" :title="title" :footer="false">
      <ly-formList v-if="visible" :fromList="fromList" ref="formListRef" @cancel="cancel" @getVal="getVal"></ly-formList>
    </ly-modal>
    <ly-modal v-model:visible="delVisible" title="系统提示" @handleOk="handleOk" :isTips="true">
      <p>确定要删除这条数据吗?</p>
    </ly-modal>
    <!-- 权限模块组件 -->
    <resourceTree ref="resourceTree1" :terminalType="terminalType" />

  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { PlusOutlined } from "@ant-design/icons-vue";
import { iptData, columns, fromList } from './role.js'
import { page, roleAdd, roleEdit } from '@/api/system/role'
import resourceTree from './resourceTree'
import { message } from 'ant-design-vue';

const visible = ref(false)
const delVisible = ref(false)
const editID = ref({})
const resourceTree1 = ref(null)
const data = ref([])
const title = ref('')
const loading = ref(false)
const searchMsg = ref({})
const formListRef = ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})


const grant = (recode, num) => {
  resourceTree1.value.onClose(true)
  resourceTree1.value.loadData(recode.id, num)
}
//删除事件
const handleOk = () => {
  delVisible.value = false;
}
//重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqPage()
}
const pageChange = (e) => {
  pagination.value = e
  reqPage()
}
const delAdd = (item) => {
  editID.value = item
  delVisible.value = true
}
// 编辑按钮
const edit = (item) => {
  editID.value = item
  fromList.value.forEach(opt => {
    opt.value = item[opt.prop]
  })
  title.value = '编辑'
  visible.value = true;
}
// 搜索事件
const searchAdd = (e) => {
  console.log(e);
  searchMsg.value = e
  reqPage()
}
//添加机构
const newAdd = () => {
  fromList.value.forEach(item => {
    item.value = null
  })
  editID.value = {}
  title.value = '添加机构'
  visible.value = true;
}
//获取表单的值
const getVal = (e) => {
  formListRef.value.fileLoading = true
  if (editID.value.id) {
    e.id = editID.value.id
    roleEdit(e).then(res => {
      if (res.code !== 10000) return
      message.success('修改成功')
      reqPage()
      cancel()
    }).finally(() => {
      if (formListRef.value) {
        formListRef.value.fileLoading = false
      }
    })
  } else {
    roleAdd(e).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      reqPage()
      cancel()
    }).finally(() => {
      if (formListRef.value) {
        formListRef.value.fileLoading = false
      }
    })
  }


}
const cancel = () => {
  visible.value = false;
}
const reqPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  page(msg).then(res => {
    console.log(res);
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total;
  }).finally(() => {
    loading.value = false;
  })
}
onMounted(() => {
  reqPage()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  height: calc(100vh - 230px);
}
</style>