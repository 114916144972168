import { putRequest, getRequest, postBodyRequest,exportRequest } from '@/utils/axios'
// 账单分页
export function reconciliationPage (params) {
  return postBodyRequest('/cs/finance/reconciliation/page', params)
}

// 订单报表
export function reconciliationBillOrder (crmId,params) {
  return postBodyRequest(`/cs/finance/reconciliation/${crmId}/billOrder`, params)
}
// 运单报表
export function reconciliationBillTrans (crmId,params) {
  return postBodyRequest(`/cs/finance/reconciliation/${crmId}/billTrans`, params)
}
// 添加订单
export function reconciliationAdd (params) {
  return postBodyRequest('/cs/finance/reconciliation/add', params)
}
// 财务结算-对账-详情
export function reconciliationInfo (reconciliationId) {
  return postBodyRequest(`/cs/finance/reconciliation/${reconciliationId}`)
}
// 发起对账
export function start (params) {
  return postBodyRequest(`/cs/flow/instance/start`,params)
}
// 对账-编辑订单
export function reconciliationEdit (params) {
  return postBodyRequest(`/cs/finance/reconciliation/edit`,params)
}

//衫德支付分页
export function sandpayPage (params) {
  return postBodyRequest(`/cs/orderpay/sandpay/page`,params)
}
// 已收总金额
export function sandpayTotal (params) {
  return getRequest(`/cs/orderpay/sandpay/total`,params)
}

// 获取支付二维码
export function getsandqrcode (params) {
  return postBodyRequest(`/cs/orderpay/sandqrcode`,params)
}

// 小车的附加费信息
export function orderVehicleFeePage (params) {
  return postBodyRequest(`/cs/order/vehicle-fee/page`,params)
}
// 运单报表
export function statementTransPage (params) {
  return postBodyRequest(`/cs/finance/statement/trans-page`,params)
}

// 车辆报表
export function statementVehiclePage (params) {
  return postBodyRequest(`/cs/finance/statement/vehicle-page`,params)
}
// 财务结算-对账-删除
export function statementVehicleDelete (reconciliationId) {
  return postBodyRequest(`/cs/finance/reconciliation/${reconciliationId}/delete`)
}
// 财务结算-拆分账单
export function splitTrans (ftsId) {
  return getRequest(`/cs/finance/reconciliation/split/trans/${ftsId}`)
}
// 修改油卡或现金
export function changeOilFee (params) {
  return putRequest(`/cs/transport-ops/change-oil-card-fee`,params)
}

// 财务结算-对账-生成结算二维码
export function reconciliationGetsandqrcode (params) {
  return getRequest(`/cs/finance/reconciliation/getsandqrcode`,params)
}

// 财务结算-对账-删除明细
export function deleteDetailId (reconciliationId,detailId,type) {
  return postBodyRequest(`/cs/finance/reconciliation/${reconciliationId}/delete/${detailId}/${type}`)
}
// 合并账单
export function reconciliationMerge(params) {
  return postBodyRequest(`/cs/finance/reconciliation/merge`,params)
}
// 取消费用
export function cancelFeeException(params) {
  return postBodyRequest(`/cs/order/vehicle-fee/cancel-fee-exception`,params)
}
// 财务-对账明细-导出
export function reconciliationExport(reconciliationId,type,params) {
  return exportRequest(`/cs/finance/reconciliation/export/${reconciliationId}/${type}`,{},params)
}

// 车辆报表-导出
export function vehicleExport(params,title) {
  return exportRequest(`/cs/finance/statement/vehicle-export`,params,title)
}
// 运单报表-导出
export function transExport(params,title) {
  return exportRequest(`/cs/finance/statement/trans-export`,params,title)
}

// 司机报表
export function driverPage(params) {
  return postBodyRequest(`/cs/finance/statement/driver-page`,params)
}
// 司机报表-导出
export function driverExport(params,title) {
  return exportRequest(`/cs/finance/statement/driver-export`,params,title)
}

// 应收应付-汇总导出
export function receivableSummaryExport(params,title) {
  return exportRequest(`/cs/finance/statement/receivable-summary-export`,params,title)
}
// 应付明细-导出
export function receivableTransExport(params,title) {
  return exportRequest(`/cs/finance/statement/receivable-trans-export`,params,title)
}
// 代收明细-导出
export function receivableArrivalExport(params,title) {
  return exportRequest(`/cs/finance/statement/receivable-arrival-export`,params,title)
}
// 应收明细-导出
export function receivableOrderExport(params,title) {
  return exportRequest(`/cs/finance/statement/receivable-order-export`,params,title)
}
// 内部代收明细-导出
export function receivableOrderInnerExport(params,title) {
  return exportRequest(`/cs/finance/statement/receivable-orderInner-export`,params,title)
}

// 利润报表
export function vehicleProfitPage(params) {
  return postBodyRequest(`/cs/finance/statement/vehicle-profit-page`,params)
}
// 利润报表-导出
export function vehicleProfitExport(params,title) {
  return exportRequest(`/cs/finance/statement/vehicle-profit-export`,params,title)
}