<template>
  <a-card :bodyStyle="{ padding: 50 }">
    <template #title>
      修改录单员
    </template>
    <a-form ref="formRef" layout="horizontal" :model="form" :rules="rules">
      <a-form-item label="新的录单员">
        <a-select v-model:value="searchSourceMan" show-search placeholder="输入姓名" style="width: 200px"
          :default-active-first-option="false" :show-arrow="false" :filter-option="false"
          :not-found-content="loading ? undefined : null" @search="searchData" @change="sourceManChange">
          <a-select-option v-if="!loading" v-for="item in optionsList" :key="item.name" :value="item.id">{{ item.name }}</a-select-option>
          <template v-if="loading" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="reason" placeholder="备注" :rows="4" />
      </a-form-item>
    </a-form>
    <div style="text-align:right"><a-button type="primary" @click="submit">提交</a-button></div>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import {employeeList } from '@/api/utils.js'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      timer: null,
      optionsList: [],
      searchSourceMan: '',
      reason: '',
      form: {
        orderId: props.orderId,
        userId: ''
      }
    })
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }
    const searchData = (e) => {
      if (e != '') {
        clearTimeout(state.timer)
        state.timer = setTimeout(() => {
          state.loading = true
          employeeList({
            name: e,
          }).then(res => {
            if (res.code === 10000) {
              state.optionsList = res.data
              state.timer = null
              state.loading = false
            }
          })
        }, 1000)
      } else state.optionsList = []
    }
    const sourceManChange = (e, v) => {
      state.form.userId = e
    }
    const submit = () => {
      if (state.form.userId != '') {
        if (state.reason != '') {
          orderPickEdit({
            params: state.form,
            type: {
              value: 0
            },
            reason: state.reason,
            subType: props.subType
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.form.userId = ''
              state.reason = ''
              emit('success', false)
            }
          })
        } else message.error('请输入备注信息')
      } else message.error('请选择新的分单员')
    }

    return {
      ...toRefs(state),
      editParam,
      searchData,
      submit,
      sourceManChange
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}

.not-archive {
  color: #999;
}
</style>
