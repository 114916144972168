<template>
  <div>
    <a-upload multiple class="upload" :disabled="state.fileLoading ?true:false" @preview="handlePreview" :file-list="state.fileIds" :remove="handleRemove" :list-type="type" :before-upload="beforeUpload">
      <div v-if="type && state.fileIds.length < maxMun">
        <LoadingOutlined v-if="state.fileLoading"></LoadingOutlined>
        <PlusOutlined v-else></PlusOutlined>
        <div> {{ text }}</div>
      </div>
      <div v-if="!type && state.fileIds.length < maxMun">
        <ly-button type="primary" :loading="false">
          <template #icon>
            <LoadingOutlined v-if="state.fileLoading"></LoadingOutlined>
            <PlusOutlined v-else></PlusOutlined>
          </template>
          <div>{{ text }}</div>
        </ly-button>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { fileUpload as upload } from '@/api/utils'
import { message } from "ant-design-vue";
const props = defineProps({
  maxMun: {
    type: Number,
    default: 5
  },
  type: {
    type: String,
    default: 'picture-card'
  },
  text: {
    type: String,
    default: '上传图片'
  },

})
const emit = defineEmits(['fileStatus'])
const previewImage = ref("")
const previewType = ref("")
const previewVisible = ref(false)
const state = reactive({
  fileList: [],
  fileLoading: false,
  fileIds: []
})

const beforeUpload = (file) => {
  state.fileList.push(file)
  handleChange1()
  return false
}
const handlePreview = async (file) => {
  if (props.type === '') return
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj))
  }
  previewType.value = file.type
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
};

// 图片上传
const handleChange1 = () => {
  if (!state.fileLoading) {
    state.fileLoading = true
    emit('fileStatus', state.fileLoading)
    const timeOut = setTimeout(() => {
      let len = state.fileList.length
      let fileLen = state.fileIds.length
      if ((len + fileLen) > props.maxMun) {
        message.error('最多只能上传' + props.maxMun + '个图片')
        state.fileList = []
        state.fileLoading = false
        emit('fileStatus', state.fileLoading)
        return
      }
      updateBatch(0, len, state.fileList)
      clearTimeout(timeOut)
    }, 500)
  }
}
// 图片上传
const updateBatch = (index, len, imageList) => {
  if (index <= len - 1) {
    const formData = new FormData()
    formData.append('file', imageList[index])
    upload(formData).then((res) => {
      if (res.code === 10000) {
        state.fileIds.push(res.data)
        if (index === len - 1) {
          setTimeout(() => {
            state.fileList = []
            message.success('图片上传成功')
            state.fileLoading = false
            emit('fileStatus', state.fileLoading)
          }, 1000)
        } else {
          updateBatch(++index, len, imageList)
        }
      } else {
        state.fileList = []
        message.error('图片上传失败')
        state.fileLoading = false
        emit('fileStatus', state.fileLoading)
      }
    })
  } else {
    return false
  }
}
// 图片移除
const handleRemove = (file) => {
  if (file.originFileObj) {
    const index = state.fileList.indexOf(file)
    const newfileList = state.fileList.slice()
    newfileList.splice(index, 1)
    state.fileList = newfileList
    state.fileIds.forEach((item, index) => {
      if (item.name === file.originFileObj.name) state.fileIds.splice(index, 1)
    })
  }
  if (file.id)
    state.fileIds.forEach((item, index) => {
      if (item.id == file.id) state.fileIds.splice(index, 1)
    })
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
defineExpose({
  state
})
</script>

<style lang="less" scoped>
:deep(.ant-upload-picture-card-wrapper) {
  width: 100% !important;
}
</style>