<template>
  <a-form :model="state.applyAccounts"  ref="formRef" :labelCol="{ flex: '120px' }" scrollToFirstError>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="付款总金额" style="display:flex">
      <a-typography-text type="danger">{{ msgInfo.amt }} 元 </a-typography-text>
      <a-typography-text type="secondary">{{ amountUppercase(msgInfo.amt) }}</a-typography-text>
    </a-form-item>
    <a-form-item label="收款方类型">
      <span>客户</span>
    </a-form-item>
    <a-form-item label="客户名称">
      <span>{{msgInfo.customerName }}</span>
    </a-form-item>
    <template v-for="(form, index) in state.applyAccounts.forms" :key="form">
      <template v-if="form.flag">
        <a-divider />
        <div style="display:flex">
          <a-typography-title :level="5">{{ form.projectName }}</a-typography-title>
          <div style="margin-left:25px">
            <span>金额：</span>
            <span>
              <a-typography-text type="danger">{{ form.amt }} 元 </a-typography-text>
              <a-typography-text type="secondary">{{ amountUppercase(form.amt) }}</a-typography-text>
            </span>
          </div>
        </div>
        <a-form-item>
          <span style="color:#344563;font-weight:700">线下资料</span>
        </a-form-item>
        <a-form-item label="收款方式" :wrapperCol="{ flex: 'auto' }" :name="['forms', index, 'mod']" :rules="{ required: true, message: '请选择收款方式', trigger: 'change' }">
          <a-select v-model:value="form.mod">
            <a-select-option value="微信">微信</a-select-option>
            <a-select-option value="支付宝">支付宝</a-select-option>
            <a-select-option value="银行卡">银行卡</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="卡号" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountNum" />
        </a-form-item>
        <a-form-item label="户名" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountName" />
        </a-form-item>
        <a-form-item label="开户行" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountBank" />
        </a-form-item>
        <a-form-item label="收款方信息" :wrapperCol="{ flex: 'auto' }">
          <a-textarea v-model:value="form.payeeInfo" />
        </a-form-item>
        <a-form-item label="收款码" :wrapperCol="{ flex: 'auto' }">
          <a-upload :file-list="form.fileList" accept="image/*" list-type="picture" @change="change(index)" :before-upload="beforeUpload" :remove="handleRemove" @preview="handlePreview">
            <a-button :disabled="form.fileList && form.fileList.length > 0">
              <CameraOutlined />
              图片
            </a-button>
          </a-upload>
        </a-form-item>
      </template>
    </template>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="备注">
      <a-textarea v-model:value="state.applyAccounts.remark" />
    </a-form-item>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="流程图片">
     <ly-upload @fileStatus="status" ref="uploadRef"></ly-upload>
    </a-form-item>
  </a-form>
  <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
    <img alt="预览图片" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>
<script setup>

import { fileUpload as upload } from '@/api/utils'
import { onMounted, reactive, ref, toRefs, watch } from 'vue'
import { CameraOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { amountUppercase } from '@/utils/util'
import { message } from 'ant-design-vue';

const props = defineProps({
  msgInfo: {
    type: Object,
    default: {}
  },
  cardAmt:{
    type: Number,
    default: 0
  },
  oilAmt:{
    type: Number,
    default: 0
  }

});
const emit = defineEmits(['fileStatus'])
const uploadRef = ref(null)
const loading = ref(false)
const  idx = ref(0)
const formRef = ref(null)
const previewVisible = ref(false)
const previewImage = ref('')
const state = reactive({
  applyAccounts: {
    forms: [],
    remark: null,
    fileIds:[]
  },
})
 const change = (index) => {
  idx.value = index
 }
const beforeUpload = (file) => {
    handleChange(file)
    return false
}
const handleChange = (file) => {
  loading.value = true
  emit('fileStatus', loading.value)
  const formData = new FormData()
    formData.append('file',file)
    upload(formData).then((res) => {
      if (res.code === 10000) {
        state.applyAccounts.forms[idx.value].fileList = [res.data]
        message.success('上传成功')
      } 
    }).finally(() => {
      loading.value = false
      emit('fileStatus', loading.value)
    })
}
const handleRemove = file => {
  state.applyAccounts.forms[idx.value].fileList = []
}
const handlePreview =  file => {
  previewImage.value = file.url
  previewVisible.value = true
}
const status = (e) => {
  loading.value = e
  emit('fileStatus', loading.value)
}
const initPay = () => {
  if (props.oilAmt > 0) {
    state.applyAccounts.forms.push({ project: '2', projectName: '油卡', flag: props.oilAmt > 0, amt: props.oilAmt, fileList: [] })
  }
  if (props.cardAmt > 0) {
    state.applyAccounts.forms.push({ project: '3', projectName: '转账', flag: props.cardAmt > 0, amt: props.cardAmt, fileList: [] })
  }
}
defineExpose({
  formRef,
  state,
  uploadRef
})
onMounted(() => {
  initPay()
})
</script>
<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 6px !important;
}

:deep(.ant-divider-horizontal) {
  margin: 15px 0 !important;
}

.clearfix {
  width: 128px;
  height: 128px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.modal_card {
  width: 400px;
}

.qa {
  transform: rotateY(180deg);
}
</style>
