<template>
  <div>
    <a-modal :visible="visible" title="账单明细" width="95%" @cancel="cancel" :centered="true" :footer="null" destroyOnClose>
      <a-card title="对账详情">
        <a-row>
          <a-col :span="4">
            <a-form-item label="客户名">
              {{ reconciliationDetail.customerName }}
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="对账人">
              {{ reconciliationDetail.sponsor }}
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="对账金额">
              {{ reconciliationDetail.amt }}
            </a-form-item>
          </a-col>
          <a-col v-if="reconciliationDetail.status?.value === 0" :span="4" style="text-align: right;">
            <a-button size="large" @click="initiateSettlement" danger>发起结算</a-button>
          </a-col>
          <a-col v-else :span="4" style="text-align: right;">
            <span>已申请结算</span>
          </a-col>
          <a-col :span="4" v-show="reconciliationDetail.amt * 1 > 0&&reconciliationDetail.status?.value === 0" style="text-align: right;">
            <a-button @click="openPayQRC">生成支付码</a-button>
          </a-col>
        </a-row>
      </a-card>

      <a-card title="订单明细" :style="{ marginTop: '24px' }" :bodyStyle="{ padding: 0 }">
        <template #extra>
          <a-space>
            <a-button v-if="reconciliationDetail.status?.value === 0" type="primary" @click="addOrder">添加订单</a-button>
            <a-button @click="exportExcel(1)">导出Excel</a-button>
          </a-space>
        </template>
        <ly-table  :scroll="{y:500}" :isLoad="true" @scrollChange="scrollChangeOrd" :data="state.orderList" rowKey="id" size="small" bordered :columns="orderReconColumns">
          <template #orderIdDel="{ record }">
            <a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="delAdd(record,1)">
              <a v-if="reconciliationDetail.status?.value === 0">删除</a>
            </a-popconfirm>
          </template>
          <template #vehicleList="{ record }">
            <div v-for="(item,index) in record.vehicleList" :key="index">
              <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
            </div>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress }}
            <div>{{ record.endAddress }}</div>
          </template>
        </ly-table>
      </a-card>

      <a-card title="运单明细" :style="{ marginTop: '24px' }" :bodyStyle="{ padding: 0 }">
        <template #extra>
          <a-space>
            <a-button v-if="reconciliationDetail.status?.value === 0" type="primary" @click="addTrans">添加运单</a-button>
            <a-button @click="exportExcel(2)">导出Excel</a-button>
          </a-space>
        </template>
        <ly-table  :scroll="{y:500}"  :isLoad="true" @scrollChange="scrollChangeTrans":data="state.transportData" rowKey="id" size="small" bordered :columns="waybillColumnsEdit">
          <template #orderIdDel="{ record }">
            <a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="delAdd(record,2)">
              <a v-if="reconciliationDetail.status?.value === 0">删除</a>
            </a-popconfirm>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress }}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #vehicleList="{ record }">
            <div v-for="(item,index) in record.vehicleList" :key="index">
              <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
            </div>
          </template>
        </ly-table>
      </a-card>
    </a-modal>
    <!-- 支付码 -->
    <PayQRC ref="payQRCref" />
    <!-- 客户结算 -->
    <ly-modal v-model:visible="settlementVisible" title="客户结算" width="33%" @handleOk="submitOk" :loading="loading">
      <Receive @fileStatus="fileStatus" v-if="settlementVisible&&reconciliationDetail.amt >= 0" ref="paymentRef" :cardAmt="state.cardAmt" :msgInfo="reconciliationDetail" />
      <Payment @fileStatus="fileStatus" v-if="settlementVisible&&reconciliationDetail.amt < 0" ref="paymentRef" :oilAmt="state.oilAmt" :cardAmt="state.cardAmt" :msgInfo="reconciliationDetail" />
    </ly-modal>
    <!-- 订单运单新增 -->
    <ly-modal :centered="true" v-model:visible="orderEditVisible" :footer="false" :title="editTitle" width="95%">
      <OrderEdit @submitSuccess="submitSuccess" :customerId="customerId" :editID="reconciliationDetail.id" v-if="orderEditVisible" :editTitle="editTitle"></OrderEdit>
    </ly-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { reconciliationInfo, start, deleteDetailId, reconciliationExport } from '@/api/finance/income'
import { orderReconColumns, waybillColumns } from '../income'
import PayQRC from '@/components/payQRC'
import Receive from './receive'
import Payment from './payment'
import OrderEdit from './orderEdit'
import { message } from 'ant-design-vue'
import { compute } from '@/utils/util'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  editID: {
    type: String,
    default: '',
  }
});

const waybillColumnsEdit = waybillColumns.value.slice(0, waybillColumns.value.length - 1)
const customerId = ref('')
const orderEditVisible = ref(false)
const editTitle = ref('')
const paymentRef = ref(null)
const payQRCref = ref(null)
const reconciliationDetail = ref({})
const settlementVisible = ref(false)
const loading = ref(false)
const state = reactive({
  orderAmt: 0,
  cardAmt: 0,
  oilAmt: 0,
  orderList: [],
  orderDataList:[],
  orderPage:50,
  settlementInfo: {
    orderSettlementIds: [],
    transSettlementIds: []
  },
  transportList: [],
  transportData:[],
  transPage:50,
})
const emit = defineEmits(['update:visible', 'stastus'])
const cancel = () => {
  emit('update:visible', false)
}

//删除
const delAdd = (record, type) => {
  deleteDetailId(reconciliationDetail.value.id, record.id, type).then(res => {
    if (res.code !== 10000) return
    message.success('删除成功')
    emit('stastus')
    getReconciliation()
  })
}
//编辑成功
const submitSuccess = () => {
  orderEditVisible.value = false
  getReconciliation()
}
//添加订单
const addOrder = () => {
  editTitle.value = '订单明细'
  orderEditVisible.value = true
}
//添加运单
const addTrans = () => {
  editTitle.value = '运单明细'
  orderEditVisible.value = true
}
const fileStatus = (e) => {
  loading.value = e
}
// 导出
const exportExcel = (type) => {
  let title = '财务-订单对账明细'
  if (type === 2) {
    title = '财务-运单对账明细'
  }
  reconciliationExport(props.editID, type, title).then(res => {
  })
}
//发起结算
const submitOk = () => {
  paymentRef.value.formRef.validate().then(() => {
    if (reconciliationDetail.value.amt >= 0) {
      let collectionAccount = paymentRef.value.form
      collectionAccount.amt = reconciliationDetail.value.amt
      collectionAccount.paymentfileId = paymentRef.value.imgFileList.length > 0 ? paymentRef.value.imgFileList[0].id : ''
      let fileIds = paymentRef.value.uploadRef.state.fileIds
      let msg = {
        bizParams: {
          collectionAccount: collectionAccount,
          billApplyId: reconciliationDetail.value.id,
          remark: collectionAccount.remark
        },
        fileIds: fileIds.map(item => item.id),
        type: 0
      }
      pushOrder(msg)
    } else {
      let froms = paymentRef.value.state.applyAccounts.forms
      froms.forEach(item => {
        if (item.fileList.length > 0) {
          item.payeeFileId = item.fileList[0].id
        }
      })
      let remark = paymentRef.value.state.applyAccounts.remark
      let fileIds = paymentRef.value.uploadRef.state.fileIds
      let msg = {
        bizParams: {
          applyAccounts: froms,
          remark: remark,
          billApplyId: reconciliationDetail.value.id
        },
        fileIds: fileIds.map(item => item.id),
        type: 1
      }
      pushOrder(msg)
    }

  })

}
const pushOrder = (msg) => {
  loading.value = true
  start(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    settlementVisible.value = false
    emit('stastus')
    cancel()
  }).finally(() => {
    loading.value = false
  })

}

const initiateSettlement = () => {
  if (state.orderList === null && state.transportList === null) {
    message.error('请先添加订单或运单，在申请结算')
    return
  }
  settlementVisible.value = true
}
const getReconciliation = () => {
  state.orderPage = 50
  state.transPage = 50
  reconciliationInfo(props.editID).then(res => {
    if (res.code === 10000) {
      customerId.value = res.data.customerId
      reconciliationDetail.value = res.data
      state.orderAmt = 0
      state.orderDataList = res.data.orderSettlementDetailDTOS ? res.data.orderSettlementDetailDTOS : []
      state.orderList =  state.orderDataList.slice(0,state.orderPage)
      state.transportList = res.data.transSettlementDetailDTOS ? res.data.transSettlementDetailDTOS : []
      state.transportData = state.transportList.slice(0,state.transPage) 
      if (state.orderList !== null && state.orderList.length > 0) {
        state.orderList.forEach(item => {
          state.settlementInfo.orderSettlementIds.push({ id: item.id, applyAmt: item.nowSettledAmt })
        })
        state.orderAmt = compute('+', ...state.orderList.map(item => item.nowSettledAmt)).result
      }
      let oilAmt = 0
      let cardAmt = 0
      if (state.transportList !== null && state.transportList.length > 0) {
        state.transportList.forEach(item => {
          state.settlementInfo.transSettlementIds.push({ id: item.id, applyAmt: item.settableAmt })
        })
        // 油卡
        oilAmt = compute('+', ...state.transportList.map(item => item.settableOilAmt)).result
        // 转账
        cardAmt = compute('+', ...state.transportList.map(item => item.settableCardAmt)).result
        state.transAmt = compute('+', ...state.transportList.map(item => item.settableAmt)).result

      }
      if (state.orderAmt < cardAmt) { //总金额少于转账金额
        state.cardAmt = compute('-', cardAmt, state.orderAmt).result
        state.oilAmt = oilAmt
      } else {
        const over =  compute('-', state.orderAmt, cardAmt).result //总价减转账的金额
        state.cardAmt = 0
        if (over < oilAmt) {
          state.oilAmt = compute('-', oilAmt, over).result
        }
      }
    } else {
      message.error(res.msg)
    }
  })
}
const openPayQRC = () => {
  let obj = {
    startAddress: null,
    endAddress: null,
    frId: '',
    orderId: props.editID,
    payType: '1',
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
const scrollChangeOrd = () => {
  if (state.orderList.length === 0) return
  if (state.orderPage >= state.orderDataList.length) return
  state.orderPage = state.orderPage + 50
  state.orderList = state.orderDataList.slice(0, state.orderPage)
}
const scrollChangeTrans = () => {
  if (state.transportData.length === 0) return
  if (state.transPage >= state.transportList.length) return
  state.transPage = state.transPage + 50
  state.transportData = state.transportList.slice(0,  state.transPage)
}
onMounted(() => {
  getReconciliation()
})
</script>

<style lang="less" scoped>
</style>