<template>
  <a-card :bodyStyle="{ padding: 50 }">
    <template #title>
      打开发票
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因" :name="['reason']">
            <a-textarea v-model:value="form.reason" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs, ref } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
import { onMounted } from 'vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {}, label: { type: String, default: '' } },
  emits: ['detail'],
  setup (props, { emit }) {

    const state = reactive({
      loading: false,
      form: {
        reason: '',
        orderId: props.orderId
      },
      rules: {
        reason:{ required: true, message: '请填写原因', trigger: 'change', type: 'string'}
      },
      empList: [],
    })
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }
    const formRef = ref(null)
    const submitText = () => {
      formRef.value
        .validate()
        .then(() => {
          let form = JSON.parse(JSON.stringify(state.form))
          orderPickEdit({
            params: form,
            type: {
              value: 0
            },
            reason: form.reason,
            subType: props.subType
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.form.reason = ''
              emit('success', false)
            }
          })
        })
    }
   

    onMounted(() => {
   
    })
    return {
      ...toRefs(state),
      editParam,
      submitText,
      formRef
    }

  }

}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}

.not-archive {
  color: #999;
}
</style>
