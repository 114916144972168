import { ref } from 'vue'
export const waitIptData = ref([
  {
    type: 'address',
    placeholder: '起运地',
    value: null,
    prop: 'startAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'address',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'select',
    placeholder: '车辆状态',
    value: null,
    prop: 'status',
    width: '23%',
    opt: [
      {
        label: '全部',
        value: 0
      },
      {
        label: '未安排',
        value: 1
      },
      {
        label: '已装车',
        value: 2
      },
      {
        label: '已发运',
        value: 3
      },
      {
        label: '中转待派',
        value: 4
      },
      {
        label: '待送车',
        value: 5
      },
    ]
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '23%'
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'sourceMan',
    width: '23%'
  },
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: '23%'
  },
  {
    type: 'select',
    placeholder: '订单来源',
    value: null,
    prop: 'orderSource',
    width: '23%',
    opt: [
      {
        label: '华月',
        value: 9
      },
      {
        label: '内部',
        value: 1
      },
    ]
  },
])
export const alreadyIptData = ref([
  {
    type: 'input',
    placeholder: '大车车牌号',
    value: null,
    prop: 'transportNo',
    width: '23%'
  },
  {
    type: 'input',
    placeholder: '客户名称',
    value: null,
    prop: 'carrierName',
    width: '23%'
  },
  {
    type: 'address',
    placeholder: '线路运输起',
    value: null,
    prop: 'startAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'address',
    placeholder: '线路运输终',
    value: null,
    prop: 'endAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'select',
    placeholder: '当前状态',
    value: null,
    prop: 'status',
    width: '23%',
    opt: [
      { label: '空闲', value: 1 },
      { label: '已装车', value: 2 },
      { label: '运输中', value: 3 },
    ]
  },
  {
    type: 'input',
    placeholder: '发车人',
    value: null,
    prop: 'dispatcher',
    width: '23%',
  },
  {
    type: 'select',
    placeholder: '更新位置',
    value: null,
    prop: 'gpsUpdateStatus',
    width: '13%',
    opt: [
      { label: '是', value: 1 },
      { label: '否', value: 0 },
    ]
  },
  {
    type: 'select',
    placeholder: '订单来源',
    value: null,
    prop: 'orderSource',
    width: '13%',
    opt: [
      {
        label: '华月',
        value: 9
      },
      {
        label: '内部',
        value: 1
      },
    ]
  },

])
export const waitColumns = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    align: 'center',
    fixed: 'left',
    width: 130,
    slots: {
      customRender: 'vinNo'
    }
  },

  {
    title: '车辆状态',
    dataIndex: 'vehicleTransStatus',
    align: 'center',
    slots: {
      customRender: 'vehicleTransStatus'
    },
    width: 80,
  },
  {
    title: '板车信息',
    dataIndex: 'transportCarNo',
    align: 'center',
    slots: {
      customRender: 'transportCarNo'
    },
    width: 80,
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    align: 'center',
    width: 200,
    slots: {
      customRender: 'startAddress'
    }
  },
  {
    title: '当前运输路线',
    dataIndex: 'nowStartAddress',
    align: 'center',
    width: 200,
    slots: {
      customRender: 'nowStartAddress'
    }
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    width: 150,
    align: 'center'
  },

  {
    title: '业务员',
    dataIndex: 'sourceMan',
    width: 80,
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center'
  },
])
export const alreayColumns = ref([
  {
    title: '大车车牌号',
    dataIndex: 'carNo',
    align: 'center',
    width: 120,
    fixed: 'left',
    slots: {
      customRender: 'carNo'
    }
  },
  {
    title: '客户名称',
    dataIndex: 'carrierName',
    width: 130,
    slots: { customRender: 'carrierName' },
    align: 'center'
  },
  {
    title: '车辆类型',
    dataIndex: 'truckType',
    width: 80,
    slots: { customRender: 'truckType' },
    align: 'center'
  },
  {
    title: '车辆状态',
    dataIndex: 'truckStatus',
    slots: { customRender: 'truckStatus' },
    width: 80,
    align: 'center'
  },
  {
    title: '安排时间',
    dataIndex: 'createTime',
    width: 80,
    align: 'center'
  },
  {
    title: '发车人',
    dataIndex: 'dispatcher',
    width: 80,
    align: 'center'
  },
  {
    title: '发车时间',
    dataIndex: 'departureTime',
    width: 80,
    align: 'center'
  },
  {
    title: '当前位置',
    dataIndex: 'latestLocation',
    width: 150,
    align: 'center'
  },
  {
    title: '当前运输路线',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    width: 150,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    width: 100,
    align: 'center',
    fixed: 'right',
    slots: { customRender: 'add' }
  }
])
export const gpsColumns = ref([
  {
    title: '大车车牌号',
    dataIndex: 'carNo',
    align: 'center',
    width: 120,
    fixed: 'left',
    slots: {
      customRender: 'carNo'
    }
  },
  {
    title: '车辆类型',
    dataIndex: 'truckType',
    width: 80,
    slots: { customRender: 'truckType' },
    align: 'center'
  },
  {
    title: '客户名称',
    dataIndex: 'carrierName',
    width: 130,
    slots: { customRender: 'carrierName' },
    align: 'center'
  },
  {
    title: '运输路线',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    width: 150,
    align: 'center'
  },
  {
    title: '最新位置',
    dataIndex: 'latestLocation',
    width: 150,
    align: 'center'
  },
  {
    title: '今日更新次数',
    dataIndex: 'todayUpdateNum',
    width: 150,
    align: 'center'
  },
  {
    title: '安排时间',
    dataIndex: 'createTime',
    width: 80,
    align: 'center'
  },
  {
    title: '发车人',
    dataIndex: 'dispatcher',
    width: 80,
    align: 'center'
  },
  {
    title: '发车时间',
    dataIndex: 'departureTime',
    width: 80,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    width: 100,
    align: 'center',
    fixed: 'right',
    slots: { customRender: 'add' }
  }
])
export const gpsIptData = ref([
  {
    type: 'input',
    placeholder: '大车车牌号',
    value: null,
    prop: 'transportNo',
    width: '23%'
  },
  {
    type: 'input',
    placeholder: '客户名称',
    value: null,
    prop: 'carrierName',
    width: '23%'
  },
  {
    type: 'address',
    placeholder: '线路运输起',
    value: null,
    prop: 'startAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'address',
    placeholder: '线路运输终',
    value: null,
    prop: 'endAddress',
    width: '23%',
    changeOn: true
  },
  {
    type: 'input',
    placeholder: '发车人',
    value: null,
    prop: 'dispatcher',
    width: '23%',
  },
])