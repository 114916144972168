import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'selectTime',
    placeholder: '派单时间起',
    value: null,
    prop: 'arrangedTimeStart',
    width: '190px',
  },
  {
    type: 'selectTime',
    placeholder: '派单时间止',
    value: null,
    prop: 'arrangedTimeEnd',
    width: '190px'
  },
  {
    type: 'selectTime',
    placeholder: '接单时间起',
    value: null,
    prop: 'receivingTimeStart',
    width: '190px'
  },
  {
    type: 'selectTime',
    placeholder: '接单时间止',
    value: null,
    prop: 'receivingTimeEnd',
    width: '190px'
  },
  {
    type: 'input',
    placeholder: '起运地',
    value: null,
    prop: 'startAddress',
    width: '190px'
  },
  {
    type: 'input',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    width: '190px'
  },
  {
    type: 'selectTime',
    placeholder: '完成时间起',
    value: null,
    prop: 'doneTimeStart',
    width: '190px'
  },
  {
    type: 'selectTime',
    placeholder: '完成时间止',
    value: null,
    prop: 'doneTimeEnd',
    width: '190px'
  },
  {
    type: 'input',
    placeholder: '派单人',
    value: null,
    prop: 'arranger',
    width: '190px'
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '190px'
  },
  {
    type: 'input',
    placeholder: '任务号',
    value: null,
    prop: 'taskId',
    width: '190px'
  },
])

export const fromList = ref([
  {
    label: '拒绝理由',
    name: 'remark',
    type: 'textarea',
    placeholder: '请输入拒绝理由',
    value: null,
    prop: 'remark',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入拒绝理由',
        trigger: 'blur'
      },
    ]
  },
])