<template>
  <a-card :bodyStyle="{padding:50}">
    <template #title>
      关闭取车信息修改
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
     <a-row :gutter="16">
       <a-col :span="12">
         <a-form-item label="车牌/车架号" name="vinNo">
           <a-input v-model:value="form.vinNo" />
         </a-form-item>
       </a-col>
     </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="是否有费用变化" name="isFee">
            <a-radio-group v-model:value="form.isFee" :options="options" />
          </a-form-item>
        </a-col>
      </a-row>
      <template v-if="form.isFee===1">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="运费" name="transFee">
              <a-input v-model:value="form.transFee" placeholder="输入运费" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="送车费" name="takeFee">
              <a-input v-model:value="form.takeFee" placeholder="输入送车费" />
            </a-form-item>
          </a-col>
        </a-row>
      </template>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因" name="reason">
            <a-textarea v-model:value="form.reason" :rows="6"
                        :maxlength="1000"
                        showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      formRef:null,
      form: {
        vinNo:'',
        orderId: props.orderId, 
        isFee: null,
        transFee:'',
        takeFee:'',
        reason: '',
      },
      rules:{
        vinNo: { required: true, message: '请输入车架号', trigger: 'blur' },
        isFee: { required: true, message: '请选择是否费用变化', trigger: 'blur',type:'number' },
        transFee:{ required: true, message: '请输入运费', trigger: 'blur' },
        takeFee:{ required: true, message: '请输入送车费', trigger: 'blur' },
        reason:{ required: true, message: '请输入原因', trigger: 'blur' }
      },
      options: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ]
    })
    const submitText = () => {
      state.formRef.validate().then(() => {
        orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason: state.form.reason,
        subType: props.subType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.form.isFee = undefined
          state.form.transFee = ''
          emit('success', false)
        }
      })

      })
    
    }
    return {
      ...toRefs(state),
      submitText
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
