import { ref } from 'vue'
import {  phoneValidate } from '@/utils/validate'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '姓名',
    value: '',
    prop: 'dockingPerson',
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '电话',
    value: null,
    prop: 'dockingMobile', 
    width: '13%'
  },
])
export const columns =ref([
  {
    title: '别名',
    dataIndex: 'alias',
    align: 'center',
  },
  {
    title: '所属城市',
    dataIndex: 'customerAddress',
    align: 'center',
  },
  {
    title: '详细地址',
    dataIndex: 'customerAddressDetail',
    align: 'center'
  },
  {
    title: '承运对接人姓名',
    dataIndex: 'carrierDockingPerson',
    align: 'center'
  },
  {
    title: '承运对接人电话',
    dataIndex: 'carrierDockingMobile',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '身份证图片',
    dataIndex: 'lookImg',
    slots:{customRender: 'lookImg'},
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots:{customRender: 'add'},
    align: 'center'
  },
])
export const fromList = ref([
  {
    label: '所属区域',
    name: 'address',
    type: 'address',
    placeholder: '请选择所属区域',
    value: null,
    prop: 'address',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择所属区域',
        trigger: 'change',
        type:'array',
      },
    ]
  },
  {
    label: '详细地址',
    name: 'details',
    type: 'input',
    placeholder: '请输入详细地址',
    value: null,
    prop: 'details',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入详细地址',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '承运对接人',
    name: 'carrierDockingPerson',
    type: 'input',
    placeholder: '请输入对接人',
    value: null,
    prop: 'carrierDockingPerson',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入对接人',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '承运对接人电话',
    name: 'carrierDockingMobile',
    type: 'input',
    placeholder: '请输入对接人电话',
    value: null,
    prop: 'carrierDockingMobile',
    width: '100%',
    rules: [
      {  
        required: true,
        message: '请输入对接人电话',
        trigger: 'blur'
      },
      { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '别名',
    name: 'alias',
    type: 'input',
    placeholder: '请输入别名',
    value: null,
    prop: 'alias',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入别名',
        trigger: 'blur'
      },
    ]
  },
])