import router from '@/router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // 进度条
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { message } from 'ant-design-vue'

NProgress.configure({
  showSpinner: false
})

const allowList = ['login', 'locking'] // no redirect allowList
const loginRoutePath = '/login'
const defaultRoutePath = '/home'

router.beforeEach((to, from, next) => {
  NProgress.start()
  /* has token */
  if (storage.get('accessToken')) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
      
        // request login userInfo
        store.dispatch('GetInfo').then(res => {
          const roles = res.data && res.data.resourceList
          // generate dynamic router
          store.dispatch('GenerateRoutes', { roles }).then(() => {
            // 根据roles权限生成可访问的路由表 
            // 动态添加可访问路由表
            // routerPackag(store.getters.addRouters)
            const routers = store.getters.asyncRouters
            routers.filter(item => {
              router.addRoute(item)
            })
          
            // console.log(router);
            // 请求带有 redirect 重定向时，登录自动重定向到该地址
            const redirect = decodeURIComponent(from.query.redirect || to.path)
          
            if (to.path === redirect) {
              // set the replace: true so the navigation will not leave a history record
              next({
                ...to,
                replace: true
              })
            } else {
              // 跳转到目的路由
              next({
                path: redirect
              })
            }
          })
          store.dispatch('getLogos')
          store.dispatch('getCitys')
          store.dispatch('getDataList')
         
        }).catch((msg) => {
          if (msg === undefined) {
          } else {
            message.error(msg)
          }
          // notification.error({
          //   message: '错误',
          //   description: '请求用户信息失败，请重试'
          // })

          // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
          store.dispatch('Logout').then(() => {
            next({
              path: loginRoutePath,
              query: {
                redirect: to.fullPath
              }
            })
          })
        })
      } else {
        if (to.matched.length ===0) {
          next('/404'); // 或者你可以指定一个错误页面的路径，例如 '/404'
        } else {
          next(); // 否则，正常导航
        }
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({
        path: loginRoutePath,
        query: {
          redirect: to.fullPath
        }
      })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach((to) => {
  // const tabs = store.state.app.tabs
  // let hasTab = false
  // for (let i = 0; i < tabs.length; i++) {
  //   if (to.name === tabs[i].name) {
  //     store.commit('TABS', {
  //       index: i,
  //       params: to.params,
  //       query: to.query
  //     })
  //     hasTab = true
  //     break
  //   }
  // }

  // if (!hasTab) {
  //   let tab = store.state.app.baseRouteList.filter((item) => {
  //     if (item.children) {
  //       return to.name === item.children[0].name
  //     } else {
  //       return to.name === item.name
  //     }
  //   })

  //   tab = tab[0]
  //   if (tab) {
  //     tab = tab.children ? tab.children[0] : tab
  //     if (to.params) {
  //       tab.params = to.params
  //     }
  //     if (to.query) {
  //       tab.query = to.query
  //     }
  //     store.commit('ADD_TAB', tab)
  //   }
  // }
  NProgress.done()
})
