<template>
  <a-card :bodyStyle="{padding:50}">
    <template #title>
      机构信息修改
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="选择新的所属机构" name="officeOrgId">
            <a-select v-model:value="form.officeOrgId" :filterOption="filterOpt" show-search placeholder="请选择">
              <a-select-option v-for="item in orgListData" :key="item.id" :label="item.name" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="备注" name="reason">
            <a-textarea v-model:value="form.reason" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { orgList } from '@/api/system/organzition'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const store = useStore()
    const formRef = ref(null)
    const state = reactive({
      loading: false,
      products: [],
      form: {
        orderId: props.orderId,
        officeOrgId: '',
        reason: '',
      },
      rules: {
        officeOrgId: { required: true, message: '请选择', trigger: 'blur', type: 'string' },
        reason: { required: true, message: '请输入原因', trigger: 'blur', type: 'string' }
      },
    })
    const orgListData = ref([])
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }

    const submitText = () => {
      formRef.value.validate().then(() => {
        orderPickEdit({
          params: state.form,
          type: {
            value: 0
          },
          reason: state.form.reason,
          subType: props.subType
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            emit('success', false)
          }
        })

      })

    }


    onMounted(() => {
      reqOrgList()
    })
    const reqOrgList = () => {
      orgList({}).then(res => {
        if (res.code !== 10000) return
        orgListData.value = res.data
        console.log(orgListData.value);
      })
    }
    const filterOpt = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return {
      ...toRefs(state),
      editParam,
      submitText,
      orgListData,
      filterOpt,
      formRef
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
