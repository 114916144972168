export const asyncRouterMap = [
  {
    path: '/system',
    name: 'system',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.system',
      icon: 'system'
    },
    children: [
      {
        path: '/system/organzition',
        name: 'organzition',
        component: () => import('@/views/system/organzition'),
        meta: {
          title: 'menu.system.organzition',
          keepAlive: false
        }
      },
      {
        path: '/system/employee',
        name: 'employee',
        component: () => import('@/views/system/employee'),
        meta: {
          title: 'menu.system.employee',
          keepAlive: false
        }
      },

      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/system/role'),
        meta: {
          title: 'menu.system.role',
          keepAlive: false
        }
      },

      {
        path: '/system/vehiclemodel',
        name: 'vehiclemodel',
        component: () => import('@/views/system/vehiclemodel'),
        meta: {
          title: 'menu.system.vehiclemodel',
          keepAlive: false
        }
      },
      {
        path: '/system/configurationSystem',
        name: 'configurationSystem',
        component: () => import('@/views/system/configurationSystem'),
        meta: {
          title: 'menu.system.configurationSystem',
          keepAlive: false
        }
      },
    ],
  },
   // 流程 
   {
    path: '/flow',
    name: 'flow',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.flow',
      icon: 'flow'
    },
    children: [{
      path: '/flow/myFlow',
      name: 'myFlow',
      component: () => import('@/views/flow/myFlow'),
      meta: {
        title: 'menu.flow.myFlow',
        keepAlive: true
      }
    },
    {
      path: '/flow/myPending',
      name: 'myPending',
      component: () => import('@/views/flow/myPending'),
      meta: {
        title: 'menu.flow.myPending',
        keepAlive: true
      }
    },
    {
      path: '/flow/myProcessed',
      name: 'myProcessed',
      component: () => import('@/views/flow/myProcessed'),
      meta: {
        title: 'menu.flow.myProcessed',
        keepAlive: true
      }
    },
    {
      path: '/flow/copyList',
      name: 'copyList',
      component: () => import('@/views/flow/copyList'),
      meta: {
        title: 'menu.flow.copyList',
        keepAlive: false
      }
    }
  ]},
  // crmManagement
  {
    path: '/crmManagement',
    name: 'crm',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.crmManagement', icon: 'crmkehuguanli', keepAlive: false },
    // only: true,
    children: [
      {
        path: '/crmManagement',
        name: 'crmManagement',
        component: () => import('@/views/crmManagement/index.vue'),
        meta: { title: 'menu.crmManagement.cooperativeCustomers' },
      }]
  },
  //订单
  {
    path: '/order',
    name: 'order',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.order',
      icon: 'order'
    },
    children: [
      {
        path: '/order/documentary',
        name: 'documentary',
        component: () => import('@/views/order/documentary/index.vue'),
        meta: { title: 'menu.order.documentary', keepAlive: true }
      },
      {
        path: '/order/smallVehicle',
        name: 'smallVehicle',
        component: () => import('@/views/order/smallVehicle/index.vue'),
        meta: { title: 'menu.order.smallVehicle', keepAlive: true }
      },
    ]
  },
  {
    path: '/transport',
    name: 'transport',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.transport',
      icon: 'transport'
    },
    children: [
      {
        path: '/transport/vehicle',// 运车
        name: 'vehicle',
        component: () => import('@/views/transport/vehicle'),
        meta: {
          title: 'menu.transport.vehicle',
          keepAlive: true
        }
      },
      {
        path: '/transport/bigcar', // 大车列表
        name: 'bigcar',
        component: () => import('@/views/transport/bigcar'),
        meta: {
          title: 'menu.transport.bigcar',
          keepAlive: true
        }
      },
      {
        path: '/transport/task',//承运
        name: 'task',
        component: () => import('@/views/transport/task'),
        meta: {
          title: 'menu.transport.task',
          keepAlive: false
        }
      },
      {
        path: '/transport/line',//线路   
        name: 'line',
        component: () => import('@/views/transport/line'),
        meta: {
          title: 'menu.transport.line',
          keepAlive: false
        }
      },
    ]
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.finance',
      icon: 'finance'    
    },
    children: [
      {
        path: '/finance/income',
        name: 'income',
        component: () => import('@/views/finance/income'),
        meta: {
          title: 'menu.finance.income',
          keepAlive: true
        }
      }, 
      {
        path: '/finance/collectionList',
        name: 'collectionList',
        component: () => import('@/views/finance/collectionList'),
        meta: {
          title: 'menu.finance.collectionList',
          keepAlive: false
        }
      },  
      {
        path: '/finance/surcharge',
        name: 'surcharge',
        component: () => import('@/views/finance/surcharge'),
        meta: {
          title: 'menu.finance.surcharge',
          keepAlive: false
        }
      }, 
      {
        path: '/finance/waybillReport',
        name: 'waybillReport',
        component: () => import('@/views/finance/waybillReport'),
        meta: {
          title: 'menu.finance.waybillReport',
          keepAlive: false
        }
      }, 
      {
        path: '/finance/vehicleReport',
        name: 'vehicleReport',
        component: () => import('@/views/finance/vehicleReport'),
        meta: {
          title: 'menu.finance.vehicleReport',
          keepAlive: false
        }
      }, 
      {
        path: '/finance/driverReport',
        name: 'driverReport',
        component: () => import('@/views/finance/driverReport'),
        meta: {
          title: 'menu.finance.driverReport',
          keepAlive: false
        }
      }, 
      {
        path: '/finance/profitReport',
        name: 'profitReport',
        component: () => import('@/views/finance/profitReport'), 
        meta: {
          title: 'menu.finance.profitReport',
          keepAlive: false
        }
      },
      {
        path: '/finance/dataCenter',
        name: 'dataCenter',
        component: () => import('@/views/finance/dataCenter'),
        meta: {
          title: 'menu.finance.dataCenter',
          keepAlive: false
        }
      }, 
  ]},
 

]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
  // path: '/transport/regionalStatistics',
  // name: 'regionalStatistics',
  // component: () => import('@/views/transport/regionalStatistics'),
  // meta: {
  //   title: 'menu.transport.regionalStatistics',
  //   keepAlive: false
  // }
}]
