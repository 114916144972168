import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'selectTime',
    placeholder: '上报时间起',
    value: '',
    prop: 'startSqlTime',
    width: '10%'
  },
  {
    type: 'selectTime',
    placeholder: '上报时间止',
    value: '',
    prop: 'endSqlTime',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '挂账对象',
    value: null,
    prop: 'name',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '上报人',
    value: null,
    prop: 'explainer',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '10%',
  },
])
export const columns = ref([
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
    width: '5%'
  },
  {
    title: '上报车牌号',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '金额',
    dataIndex: 'amount',
    align: 'center'
  },
  {
    title: '挂账对象',
    dataIndex: 'carrierName',
    align: 'center'
  },
  {
    title: '挂账司机',
    dataIndex: 'driverName',
    align: 'center'
  },
  {
    title: '费用类型',
    dataIndex: 'exceptionType.label',
    align: 'center'
  },
  {
    title: '费用凭证',
    dataIndex: 'fileId',
    slots: { customRender: 'fileId' },
    align: 'center'
  },
  {
    title: '备注',
    dataIndex: 'exceptionExplain',
    align: 'center'
  },
  {
    title: '上报人',
    dataIndex: 'explainer',
    align: 'center'
  },
  {
    title: '上报时间',
    dataIndex: 'explainTime',
    align: 'center'
  },
  {
    title: '取消原因',
    dataIndex: 'auditRemark',
    align: 'center'
  },
])
export const fromList = ref([
  {
    label: '原因',
    name: 'remark',
    type: 'textarea',
    placeholder: '填写原因',
    value: null,
    prop: 'remark',
    width: '63%',
    rules: [
      {
        required: true,
        message: '请填写取消原因',
        trigger: 'blur'
      },
    ]
  },
])