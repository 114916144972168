import lySearchList from '@/components/ly-searchList'
import lyFormList from '@/components/ly-formList'
import lyButton from '@/components/ly-bottom'
import lyTable from '@/components/ly-table'
import lyModal from '@/components/ly-modal'
import lyUpload from '@/components/ly-upload' 
import lyTwoForm from '@/components/ly-twoForm'
import lyPictureViewer from '@/components/ly-pictureViewer'
import hyContract from '@/components/hy-contract'
import lyImageModal from '@/components/ly-imageModal'
import lyBatchUpload from '@/components/ly-batchUpload'
import lyUploadList from '@/components/ly-uploadList'  
import lyRichText from '@/components/ly-richText'
let components = {
    install:function(Vue){
        Vue.component("lySearchList",lySearchList)
        Vue.component("lyFormList",lyFormList)
        Vue.component("lyButton",lyButton)
        Vue.component("lyTable",lyTable)
        Vue.component("lyModal",lyModal)
        Vue.component("lyUpload",lyUpload)
        Vue.component("lyTwoForm",lyTwoForm)
        Vue.component("lyPictureViewer",lyPictureViewer)
        Vue.component("hyContract",hyContract)
        Vue.component("lyImageModal",lyImageModal)
        Vue.component("lyBatchUpload",lyBatchUpload)
        Vue.component("lyUploadList",lyUploadList)
        Vue.component("lyRichText",lyRichText)
    }
}
export default components;
