import { ref } from 'vue'
export const formList = ref([
  {
    label: '抄送人',
    name: 'copyPeopleIds',
    type: 'selectSearchMore',
    placeholder: '请搜索选择',
    value: [],
    prop: 'copyPeopleIds',
    width: '100%',
    opt:[],
    rules: [
      {
        required: true,
        message: '请搜索选择',
        trigger: 'blur',
        type:'array'
      },
    ]
  },
  {
    label: '内容',
    name: 'content',
    type: 'textarea',
    placeholder: '填输入抄写内容',
    value: null,
    prop: 'content',
    width: '63%',
    rules: [
      {
        required: false,
        message: '',
        trigger: 'blur'
      },
    ]
  },
])

