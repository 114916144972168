const getters = {
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  tabs: state => state.app.tabs,
  roles: state => state.user.roles,
  userName: state => state.user.name,
  carrierName: state => state.user.carrierName,
  info: state => state.user.info,
  asyncRouters: state => state.permission.asyncRouters,
  uat: state => state.user.uat,
  logos: state => state.user.logo
}

export default getters
