<template>
  <div id="components-back-top-custom">
    <a-tooltip>
      <template #title>回到顶部</template>
      <a-back-top>
        <a-button type="primary" shape="circle">顶</a-button>
      </a-back-top>
    </a-tooltip>
  </div>

  <a-card>
    <a-form ref="formRef" :model="form" :rules="rules" scrollToFirstError hideRequiredMark>
      <table class="table">
        <thead>
          <tr>
            <th class="left-td" colspan="10">
              <a-form-item label="承运主体" :wrapperCol="{ flex: 'auto' }">
                <a-select v-model:value="form.order.carrierBodyId" style="width: 300px" placeholder="请选择承运商" :bordered="false" @change="carrierChange">
                  <a-select-option v-for="item in carrierList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </th>
            <th class="center" colspan="6">
              <!-- :name="['order','sourceMan']" -->
              <a-form-item label="业务员" style="width: 200px" :wrapperCol="{ flex: 'auto' }">
                <a-select v-model:value="form.order.sourceMan" show-search placeholder="请选择业务员" :bordered="false" @change="sourceManChange">
                  <a-select-option v-for="item in employeeOpt" :key="item.id" :value="item.name">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </th>
            <th class="right-td" colspan="6">录单员：{{ form.order.salesman }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!--  客户 -->
            <td rowspan="3" colspan="2" width="6%">
              <strong>客户</strong>
              <a-button type="link" @click="chooseCustomerVisible = true">选择</a-button>
            </td>
            <td class="label-td" colspan="2" width="5%">姓名</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'customerName']">
                <!-- <a-input v-model:value="form.order.customerName" autocomplete="off" allowClear /> -->
                {{ form.order.customerName }}
              </a-form-item>
            </td>
            <!-- 对接人 -->
            <td rowspan="3" colspan="2" width="6%">
              <div style="font-weight:bold">起运地</div>
              <strong>交车人</strong>
              <a-button type="link" @click="copyConsignor(0)">同步</a-button>
            </td>
            <td class="label-td" colspan="2" width="6%">姓名</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'name']">
                <a-input v-model:value="form.order.consignor.name" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td rowspan="3" colspan="2" width="6%">
              <div style="font-weight:bold">目的地</div>
              <strong>取车人</strong>
              <a-button type="link" @click="copyConsignor(1)">同步</a-button>
            </td>
            <td class="label-td" colspan="2" width="6%">姓名</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'name']">
                <a-input v-model:value="form.order.picker.name" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <!--  客户 -->
            <td class="label-td" colspan="2" width="5%">证件号</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'customerId']">
                {{ form.order.certificateNumber }}
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">证件号</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'certificateNumber']">
                <a-input v-model:value="form.order.consignor.certificateNumber" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td class="label-td" colspan="2" width="6%">证件号</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'certificateNumber']">
                <a-input v-model:value="form.order.picker.certificateNumber" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <!--  客户 -->
            <td class="label-td" colspan="2" width="5%">电话</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'customerMobile']">
                {{ form.order.customerMobile }}
              </a-form-item>
            </td>
            <!-- 对接人 -->
            <td class="label-td" colspan="2" width="6%">电话</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'mobile']">
                <a-input v-model:value="form.order.consignor.mobile" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td class="label-td" colspan="2" width="6%">电话</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'mobile']">
                <a-input v-model:value="form.order.picker.mobile" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">对接人</td>
            <td colspan="19" class="left-td">
              <a-select v-model:value="form.order.businessContactPerson" style="width:300px" placeholder="对接人">
                <a-select-option v-for="item in businessOpt" @click="chooseContact(item)" :value="item.dockingPerson" :key="item.dockingPerson">{{
                  item.info
                }}</a-select-option>
              </a-select>
              <a class="m-l1" @click="newAdd">+新增对接人</a>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2" width="6%">起运地</td>
            <td colspan="6" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order','startAddress']">
                <a-cascader v-model:value="form.order.startAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" @change="setStartAddress" placeholder="请选择" />
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">目的地</td>
            <td colspan="6" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order','endAddress']">
                <a-cascader v-model:value="form.order.endAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" @change="setEndAddress" placeholder="请选择目的地" />
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">所属机构</td>
            <td colspan="6">
              <a-row :gutter="8">
                <a-col :span="8">
                  <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'officeOrgId']">
                    <a-select @select="orgChange" show-search  :filterOption="filterOpt"  v-model:value="form.order.officeOrgId" allowClear >
                      <a-select-option v-for="item in orgListData" :key="item.id" :label="item.name"  :value="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">发票</td>
            <td colspan="6" class="left-td">
              <a-form-item :wrapperCol="{ flex: 'auto' }">
                <a-switch v-model:checked="form.order.isInvoice" :checkedValue="1" :unCheckedValue="0" />
              </a-form-item>
            </td>
            <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">等通知放车</span></td>
            <td colspan="6">
              <div style="display:flex;justify-content: left;align-items:center">
                <a-radio-group v-model:value="form.order.isNoticeReleaseCar" @change="isNoticeReleaseCarChange">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
                <div class="isInspectionPay-div" v-if="form.order.isNoticeReleaseCar === 1" style="flex:1">
                  <a-popconfirm ok-text="确认" cancel-text="">
                    <template #title>
                      <div style="width:250px">
                        <div>备注填写</div>
                        <a-textarea v-model:value="form.order.noticeRemark" showCount :maxlength="100" :rows="3" placeholder="备注" />
                      </div>
                    </template>
                    <a>备注填写</a>
                  </a-popconfirm>
                </div>
              </div>
            </td>
            <td class="label-td" colspan="2" width="6%">结算方式</td>
            <td colspan="6">
              <a-row :gutter="8">
                <a-col :span="8">
                  <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'orderSettlement']">
                    <a-select v-model:value="form.order.orderSettlement" allowClear>
                      <a-select-option :value="1">货到付款</a-select-option>
                      <a-select-option :value="2">起运地付清</a-select-option>
                      <a-select-option :value="3">预付X到付X</a-select-option>
                      <a-select-option :value="5" >记账</a-select-option>
                      <a-select-option :value="4" >月结</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="16" v-if="form.order.orderSettlement === 3">
                  <a-form-item label="预付（元）" :name="['order', 'advanceCharge']">
                    <a-input v-model:value="form.order.advanceCharge" prefix="￥" />
                  </a-form-item>
                </a-col>
                <!-- <a-col :span="16" v-if="form.order.orderSettlement.value === 5">
                  <a-form-item label="记账期限" :name="['order', 'deadline']">
                    <a-input-number v-model:value="form.order.deadline" />
                  </a-form-item>
                </a-col> -->
              </a-row>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">返款</td>
            <td colspan="2" class="left-td">
              <a-form-item :wrapperCol="{ flex: 'auto' }">
                <a-switch v-model:checked="form.order.isRefund" :checkedValue="1" :unCheckedValue="0" />
              </a-form-item>
            </td>
            <template v-if="form.order.isRefund">
              <td class="label-td" colspan="2" width="6%">返款金额</td>
              <td colspan="2">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'refundFee']">
                  <a-input v-model:value="form.order.refundFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">返款人</td>
              <td colspan="6">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order','refunder']">
                  <a-select v-model:value="form.order.refunder">
                    <a-select-option v-for="item in businessOpt" :value="item.dockingPerson" :key="item.dockingPerson" @click="refunderChange(item)">{{
                      item.info
                    }}</a-select-option>
                  </a-select>
                </a-form-item>

              </td>
              <td class="label-td" colspan="2" width="6%">返款电话</td>
              <td colspan="6">
                {{ form.order.refunderMobile }}
              </td>
            </template>
            <td v-else colspan="20" />
          </tr>
          <tr>
            <td colspan="2" width="6%" class="label-td">备注</td>
            <td colspan="22">
              <a-textarea v-model:value="form.order.remark" :rows="3" :maxlength="100" showCount />
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <template v-for="(item, index) in  form.orderVehicles" :key="index">
          <table class="table" @click="indexNum = index">
            <tr class="vehicle-div">
              <td class="label-td" style="position: relative;" rowspan="7" width="5.4%">
                <div class="batch-data"><a-button v-if="index <= 0" type="primary" size="small" @click="batchGiveData">批量赋值</a-button></div>
                <strong>车辆{{ index + 1 }}</strong>
                <a-button type="link" @click="copyVehicle(index)">复加</a-button>
                <a-button type="link" v-if="form.orderVehicles.length > 1" @click="deleteVehicle(index)">删除</a-button>
              </td>
              <td class="label-td" colspan="2" width="8%">车型</td>
              <td colspan="4" width="20%" style="padding-left:0;padding-right:0;justify-content: space-around;">
                <div class="vehicle-brand">
                  <a-form-item> 
                    <a-cascader v-model:value="item.brands"  :options="brandsData" @select="brandsChange"  placeholder="请选择车型" :show-search="{ filter }" style="width:170px" />
                    <a-select v-model:value="item.vehicleType" style="width: 100px" placeholder="请选择类型" @change="vehicleTypeChange">
                      <a-select-option :value="1">新车</a-select-option>
                      <a-select-option :value="2">二手车</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </td>
              <td class="label-td" colspan="2" width="8%">车架号</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'vinNo']" :rules="[{ required: true, message: '请填写车架号', trigger: 'blur' }, { min: 6, max: 20, message: '车架号长度为6-20位', trigger: 'blur' }, { validator: vinNoRepeat, message: '车架号重复', trigger: 'blur' }]">
                  <a-input v-model:value="item.vinNo" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">车辆状况</td>
              <td colspan="4" class="left-td" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }">
                  <a-select v-model:value="item.status" allowClear>
                    <a-select-option :value="1">正常</a-select-option>
                    <a-select-option :value="2">开不动</a-select-option>
                    <a-select-option :value="3">事故车</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">运费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.freight" prefix="￥" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="6%">保险</td>
              <td colspan="5" width="20%">
                <a-form-item>
                  <a-radio-group v-model:value="item.insuranceCompany">
                    <div class="flex" style="width:100%">
                      <a-radio :value="1">否</a-radio>
                      <a-radio :value="2">太平洋（江）</a-radio>
                      <a-radio :value="7">财险</a-radio>
                    </div>
                  </a-radio-group>
                </a-form-item>
              </td>
              <td class="label-td" colspan="5" width="22%">
                {{ item.insuranceCompany === 2 ? '太平洋（江）' : item.insuranceCompany === 7 ? '财险' : ''}}
              </td>
              <td class="label-td" colspan="2" width="8%">保额（万元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany > 1">
                  <a-input v-model:value="item.insuranceFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">保费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany > 1">
                  <a-input v-model:value="item.insurancePremium" prefix="￥" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">取车</td>
              <td colspan="2" width="8%" style="height:43px;">
                <div style="display:flex;align-items:center;justify-content: space-between;">
                  <a-switch v-model:checked="item.isPlaceIn" :checkedValue="1" :unCheckedValue="0" @change="pickChange" />
                  <a-select style="width:100px" v-if="item.isPlaceIn === 1" v-model:value="item.pickType" allowClear placeholder="取车方式">
                    <a-select-option :value="1">大板车</a-select-option>
                    <a-select-option :value="2">小板车</a-select-option>
                    <a-select-option :value="3">地跑</a-select-option>
                  </a-select>
                </div>
              </td>
              <td v-show="item.isPlaceIn === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isPlaceIn === 0" class="label-td" :colspan="item.isPlaceIn === 0 ? 12 : 5" width="22%" style="padding:5px 2px"></td>
              <td v-show="item.isPlaceIn === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isPlaceIn === 0" class="label-td" :colspan="item.isPlaceIn === 0 ? 12 : 5" width="22%" style="padding:5px 2px"></td>

              <td class="label-td" colspan="2" width="8%" v-show="item.isPlaceIn === 1">
                <span>取车费（元）</span>
              </td>
              <td colspan="6" width="22%" v-if="item.isPlaceIn === 1">
                <a-form-item :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.placeInFee" prefix="￥" />
                </a-form-item>
              </td>
              <td v-show="item.isPlaceIn === 1" class="label-td" colspan="2" width="8%">取车地址</td>
              <td v-show="item.isPlaceIn === 1" colspan="8" width="36%">
                <a-form-item :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.placeInAddress" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">送车</td>
              <td colspan="2" style="height:43px">
                <div style="display:flex;align-items:center;justify-content: space-between;">
                  <a-switch v-model:checked="item.isTakeOut" :checkedValue="1" :unCheckedValue="0" @change="takeChange" />
                  <a-select v-if="item.isTakeOut === 1" style="width:100px;" v-model:value="item.takeType" allowClear placeholder="送车方式">
                    <a-select-option :value="1">大板车</a-select-option>
                    <a-select-option :value="2">小板车</a-select-option>
                    <a-select-option :value="3">地跑</a-select-option>
                  </a-select>
                </div>
              </td>

              <td v-show="item.isTakeOut === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isTakeOut === 0" class="label-td" :colspan="item.isTakeOut === 1 ? 12 : 5" width="22%" style="padding:5px 2px"></td>
              <td v-show="item.isTakeOut === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isTakeOut === 0" class="label-td" :colspan="item.isTakeOut === 1 ? 5 : 12" width="8%" style="padding:5px 2px"></td>

              <td class="label-td" colspan="2" width="12%" v-show="item.isTakeOut === 1">送车费（元）</td>
              <td colspan="6" width="22%" v-show="item.isTakeOut === 1">
                <a-form-item v-if="item.isTakeOut" :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.takeOutFee" prefix="￥" />
                </a-form-item>
              </td>
              <td v-show="item.isTakeOut === 1" class="label-td" colspan="2" width="8%">送车地址</td>
              <td v-show="item.isTakeOut === 1" colspan="8" width="36%">
                <a-form-item v-if="item.isTakeOut" :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.takeOutAddress" />
                </a-form-item>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">备注</td>
              <td colspan="22">
                <a-textarea v-model:value="item.remark" :rows="2" :maxlength="100" showCount />
              </td>
            </tr>
          </table>
        </template>
      </div>
      <div class="save-button">
        <a-space>
          <a-button type="primary" :loading="subLoading" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
  <ChooseCustomer v-if="chooseCustomerVisible" v-model:visible="chooseCustomerVisible" @select="chooseCustomerCle" />
  <a-modal footer="" width="20%" v-model:visible="dockingVisible" title="添加业务对接人" destroyOnClose>
    <NewDocking @getVal="getVal" @cancel="dockingVisible = false"></NewDocking>
  </a-modal>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import NewDocking from './newDocking.vue';
import { mobileValidate, phoneValidate, moneyValidate, idNumberValidate, positiveNumberValidate, number } from '@/utils/validate'
import ChooseCustomer from '@/views/components/chooseCustomer'
import { cloneDeep } from 'lodash-es'
import { employeeList, businessList } from '@/api/utils'
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { orderAdd, orderInfo, orderEdit } from '@/api/order/order'
import { useRouter, useRoute } from 'vue-router';
import { businessAdd } from '@/api/crmManagement/crm'
import { orgList } from '@/api/system/organzition'
import { bodyList } from '@/api/system/carrierSubject'
const router = useRouter()
const route = useRoute()
const store = useStore()
const formRef = ref(null)
const chooseCustomerVisible = ref(false)
const carrierList = ref([])
const orgListData = ref([])
const brandsData = ref([])
const employeeOpt = ref([])
const businessOpt = ref([])
const businessID = ref('')
const editID = ref('')
const subLoading = ref(false)
const form = reactive({
  order: {
    carrierBodyId: '',
    sourceMan: null,
    salesman: store.getters.userName,
    certificateNumber: '',
    customerId: '',
    customerMobile: '',
    customerName: '',
    businessContactPerson: '',
    businessContactMobile: '',
    officeOrgName:'',
    officeOrgId:'',
    consignor: {
      name: '',
      certificateNumber: '',
      mobile: ''
    },
    picker: {
      name: '',
      certificateNumber: '',
      mobile: ''
    },
    startAddress: null,
    endAddress: null,
    orderSettlement: '',
    advanceCharge: '',
    deadline: '',
    isInvoice: null,
    isNoticeReleaseCar: '',
    noticeRemark: '',
    isRefund: '',
    refundFee: '',
    refunder: '',
    refunderMobile: '',
    remark: '',
  },
  pickTypes: [],
  orderVehicles: [
    {
      brands: null,
      brand: '',
      model: '',
      vehicleType: '',
      vinNo: '',
      status: '',
      freight: '',
      insuranceCompany: '',
      insuranceFee: '',
      insurancePremium: '',
      isPlaceIn: 0,
      pickType: null,
      placeInFee: '',
      placeInAddress: '',
      isTakeOut: 0,
      takeType: null,
      takeOutFee: '',
      takeOutAddress: '',
      remark: ''
    }
  ],
  isPlaceInCheck: [{ required: true, message: '请填写取车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
  isTakeOutCheck: [{ required: true, message: '请填写送车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
})
const rules = ref({
  order: {
    startAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' },
    endAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' },
    orderSettlement: { required: true, message: '请选择', trigger: 'change', type: 'number' },
    officeOrgId: { required: true, message: '请选择', trigger: 'change' ,},
    refundFee: [{ required: true, message: '请填写返款金额', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
    refunder: { required: true, message: '请选择,如果没有请点击新增对接人！', trigger: 'change',},
  }
})
const dockingVisible = ref(false)
const newAdd = () => {
  if (!form.order.customerName) {
    message.error('请先选择客户')
    return
  }
  dockingVisible.value = true
}
//机构名称
const orgChange = (e,v) => {
  form.order.officeOrgName = v.label
}
// 同步
const copyConsignor = (num) => {
  if (num === 1) form.order.picker = { ...form.order.consignor }
  if (num === 0) {
    form.order.consignor.mobile = form.order.customerMobile
    form.order.consignor.name = form.order.customerName
    form.order.consignor.certificateNumber = form.order.certificateNumber
  }
}
//保存
const save = () => {
  if (!form.order.customerName) {
    message.error('请选择客户')
    return
  }
  const status = form.orderVehicles.map(item => {
    if (item.vinNo.indexOf('虚') === 0) return item.vinNo.indexOf('虚')
  })

  if (status.includes(0)) {
    message.error('存在虚拟车架号,请先查看图片或修改')
    return
  }
  formRef.value.validate().then(() => {
    subLoading.value = true
    let msg = {
      order: cloneDeep(form.order),
      orderVehicles: cloneDeep(form.orderVehicles)
    }
    msg.orderVehicles.forEach(item => {
      if(item.brands?.length>0){ 
        item.brand = item.brands[0]
        item.model = item.brands[1]
      }else{
        item.brand = ''
        item.model = ''
      }
    })
    msg.order.startAddress = {
      province: msg.order.startAddress[0],
      city: msg.order.startAddress[1],
      area: msg.order.startAddress[2]
    }
    msg.order.endAddress = {
      province: msg.order.endAddress[0],
      city: msg.order.endAddress[1],
      area: msg.order.endAddress[2]
    }
    // 编辑
    if (editID.value) {
      msg.order.orderId = editID.value
      orderEdit(msg).then(res => {
        if (res.code !== 10000) return
        message.success(res.msg)
        router.back()
      }).finally(() => {
        subLoading.value = false
      })
      return
    }
    // 新增
    orderAdd(msg).then(res => {
      if (res.code !== 10000) return
      message.success(res.msg)
      router.push({ path: '/order/documentary' })
    }).finally(() => {
      subLoading.value = false
      })
  })
}
//添加业务对接人
const getVal = (e) => {
  let msg = cloneDeep(e)
  msg.customerId = businessID.value
  businessAdd(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    dockingVisible.value = false
    reqBusinessList(businessID.value)
  })
}
//选择客户
const chooseCustomerCle = (e, isEdit) => {
  if (!isEdit) {
    form.order.customerId = e.id
    form.order.customerMobile = e.chargeMobile
    form.order.customerName = e.name
    form.order.customerMobile = e.chargeMobile
    form.order.certificateNumber = e.certificateNumber
    form.order.businessContactPerson = ''
    form.order.businessContactMobile = ''
  }
  businessID.value = e.id
  reqBusinessList(e.id)
}
//选择对接人
const chooseContact = (item) => {
  form.order.businessContactMobile = item.dockingMobile
}
//返款电话
const refunderChange = (item) => {
  form.order.refunderMobile = item.dockingMobile
}
// 复加
const copyVehicle = index => {
  form.orderVehicles.splice(index + 1, 0, cloneDeep(form.orderVehicles[index]))
  form.orderVehicles[index + 1].orderVehicleId = ''
  form.orderVehicles[index + 1].vinNo = ''
}
const reqEmployeeList = () => {
  employeeList({}).then(res => {
    if (res.code !== 10000) return
    let arr = cloneDeep(store.state.app.carBrand) 
    arr.forEach( item => {
      item.value = item.label
      if(item.children){
        item.children.forEach(items => {
          items.value = items.label
        })
      }
    })
    brandsData.value = arr
    employeeOpt.value = res.data
  })
}
const reqBusinessList = (id) => {
  businessList({
    customerId: id
  }).then(res => {
    if (res.code !== 10000) return
    res.data.forEach(item => {
      item.info = item.dockingPerson + ' ' + item.dockingMobile
    })

    businessOpt.value = res.data
  })
}
const reqOrderInfo = (id) => {
  orderInfo(id).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    let order = res.data.order
    for (let key in order) {
      for (let val in form.order) {
        if (key == val) {
          if (order[key]?.label) {
            form.order[key] = order[key]?.value
          } else if (key == 'endAddress' || key == 'startAddress') {
            form.order[key] = [order[key].province, order[key].city, order[key].area]
          }
          else {
            form.order[key] = order[key]
          }
        }
      }
    }
    chooseCustomerCle({ id: order.customerId }, true)
    res.data.orderVehicles.forEach(opt => {
      opt.brands = [opt.brand, opt.model]
      for (let key in opt) {
        if (opt[key]?.label) {
          opt[key] = opt[key].value
        }
      }
    })
    form.orderVehicles = res.data.orderVehicles
   
  })
}
const deleteVehicle = index => {
  form.orderVehicles.splice(index, 1)
}
// 批量赋值
const batchGiveData = () => {
  let modData = JSON.stringify(form.orderVehicles[0])
  form.orderVehicles.forEach(item => {
    for (const key in item) {
      if (key === 'orderVehicleId' || key === 'vinNo') continue
      item[key] = JSON.parse(modData)[key]
    }
  })
}
//机构列表
const reqOrgList = () => {
  orgList({}).then( res => {
    if (res.code !== 10000) return
    orgListData.value = res.data
  })
}
//
const reqBodyList = () => {
  bodyList({}).then( res => {
   if(res.code !== 10000) return
   if(!editID.value){
    form.order.carrierBodyId =res.data?.length> 0 ? res.data[0].id : null
   }
   carrierList.value = res.data
  })
} 
const filterOpt = (input,option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
} 
onMounted(() => {
  reqBodyList()
  reqEmployeeList()
  reqOrgList()
  if (route.query.id) {
    editID.value = route.query.id
    reqOrderInfo(route.query.id)
  }
})
</script>
<style lang="less" scoped>
.vehicle-brand {
  :deep(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: center;
  }
}

#components-back-top-custom .ant-back-top {
  bottom: 160px;
  right: 40px;
}

.batch-data {
  width: 100%;
  position: absolute;
  top: 10px;
  left: 1%;
  z-index: 10;
}

.table {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }

  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 340px;
  }

  .left-td {
    text-align: left;
  }

  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .ant-form-item {
    margin: 0;
  }
}

.save-button {
  text-align: right;
}

.vehicle-div {
  background: #eee;
}

.isInspectionPay-div {
  text-align: left;
}
</style>
