import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 分页
export function page (params) {
  return postBodyRequest('/cs/org/page', params)
}
// 机构新增编辑
export function orgSave (params) {
  return postBodyRequest('/cs/org/save', params)
}
export function orgDelete (id) {
  return getRequest(`/cs/org/delete?id=${id}` )
}
// 所有机构list
export function orgList (params) {
  return postBodyRequest('/cs/org/list', params)
}