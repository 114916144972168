<template>
  <div>
    <a-modal :visible="visible" :title=" type === 1 ? '取车委托' : type===2 ? '运车委托': type === 3 ? '送车委托' : '' " width="600px" @cancel="cancel" footer="">
      <div v-if="type === 1">
        <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
        <p class="font-color2">交车人姓名：{{ dispatchDetail.consignor }}</p> 
        <p class="font-color2">交车人电话：{{ dispatchDetail.consignorMobile }}</p>
        <p class="font-color2">交车人身份证：{{ dispatchDetail.consignorCertificateNumber }}</p>
        <p class="font-color2" v-if="dispatchDetail.orderRemark">订单备注：{{ dispatchDetail.orderRemark }}</p>
        <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
        <p class="font-color2">取车信息：{{ dispatchDetail.pickType ? dispatchDetail.pickType.label : '' }}&nbsp;{{
                dispatchDetail.placeInAddress }}</p>
      </div>
      <div v-if="type === 2">
        <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
        <p class="font-color2">起运地：{{dispatchDetail.startAddress}}</p>
        <p class="font-color2">目的地：{{dispatchDetail.endAddress}} {{ dispatchDetail.picker }} {{ dispatchDetail.pickerMobile }}</p>
        <p class="font-color2"><span >
            订单到付: <a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag></span>
            <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span></p>
            <p v-if="dispatchDetail.isPeerCollection?.value === 1">代收金额：<span style="color:#D9424E">￥{{dispatchDetail.peerCollectionAmt}}</span>&nbsp;&nbsp;&nbsp;请使用代收码收款<span style="font-size:20px">→</span>
            &nbsp;&nbsp;&nbsp;<a @click="CollectionAdd(dispatchDetail)">查看代收码</a></p>
      </div>
      <div v-if="type === 3">
        <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
        <p class="font-color2">收车人姓名：{{ dispatchDetail.picker }}</p>
        <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.pickerMobile }}</p>
        <p class="font-color2">收车人身份证：{{ dispatchDetail.pickerCertificateNumber }}</p>
        <p class="font-color2"><span >
            订单到付：<a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag></span>
          <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span>
        </p>
        <p class="font-color2" v-if="dispatchDetail.orderRemark ">订单备注：{{ dispatchDetail.orderRemark }}</p>
        <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
        <p class="font-color2">送车信息：{{ dispatchDetail.takeType ? dispatchDetail.takeType.label : '' }}
          <span v-if="dispatchDetail.takeOutAddress">&nbsp;{{ dispatchDetail.takeOutAddress }}</span>
        </p>
        <p v-if="dispatchDetail.isPeerCollection?.value === 1" >代收金额：<span style="color:#D9424E">￥{{dispatchDetail.peerCollectionAmt}}</span>&nbsp;&nbsp;&nbsp;请使用代收码收款<span style="font-size:20px">→</span>
        &nbsp;&nbsp;&nbsp;<a @click="CollectionAdd(dispatchDetail)">查看代收码</a></p>
      </div>
         <!-- 付款码 -->
         <PayModal ref="payQRCrefModal" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import PayModal from '@/views/transport/components/payModal'
const emit = defineEmits(['visible:update'])
const props = defineProps({
  type: {
    type: Number,
    default: 0
  },
  dispatchDetail: {
    type: Object,
    default: {}
  },
  visible:{
    type: Boolean,
    default: false
  }
})
const payQRCrefModal= ref(null)
const cancel = () => {
  emit('update:visible',false)
}
// 代收码
const CollectionAdd = (item) => {
  let obj = {
    startAddress: item.startAddress,
    endAddress: item.endAddress,
    taskId: item.taskId,
    orderId: item.orderId,
    payType: 1,
    vinNo:item.vinNo
  } 
  setTimeout(() => {
    payQRCrefModal.value.openWindow(obj)
  }, 10)
}

</script>

<style lang="less" scoped>
</style>