import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'selectTime',
    placeholder: '收款时间起',
    value: null,
    prop: 'startTime',
    width: '200px',
  },
  {
    type: 'selectTime',     
    placeholder: '收款时间止',
    value: null,
    prop: 'endTime',
    width: '13%'
  },
  {
    type: 'select',
    placeholder: '支付状态',
    value: null,
    prop: 'paymentStatus',   
    width: '10%',
    opt:[
      {
        label:'未支付',
        value:0
      },
      {
        label:'已支付',
        value:1
      },
    ]
  },
  { 
    type: 'input',
    placeholder: '流水号',
    value: null,
    prop: 'orderCode',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: '10%'
  }
])
export const columns =ref([
  {
    title: '序号',
    dataIndex: 'idx',
    slots: { customRender: 'idx' },
    align: 'center',
  },
  {
    title: '交易流水号',
    dataIndex: 'orderCode',
    align: 'center',  
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '收款码生成时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '生成人',
    dataIndex: 'creator',
    align: 'center',
  },
  {
    title: '收款金额',
    dataIndex: 'amount',
    align: 'center',
  },
  {
    title: '支付状态',
    dataIndex: 'paymentStatus',
    align: 'center',
    slots: { customRender: 'paymentStatus' },
  },
  {
    title: '收款时间',
    dataIndex: 'payTime',
    align: 'center',
  },

])