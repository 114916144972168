import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 分页
export function page (params) {
  return postBodyRequest('/cs/employee/page', params)
}
//员工新增
export function employeeAdd (params) {
  return postBodyRequest('/cs/employee/add', params)
}
// 员工的详情
export function employeeUserId (userId) { 
  return getRequest(`/cs/employee/${userId}`)
}
//员工编辑
export function employeeEdit (params) {
  return postBodyRequest('/cs/employee/edit', params)
}
//员工状态设置 启用-停用
export function isEnable (params) {
  return getRequest(`/cs/employee/is-enable?id=${params}`)
}

// 所属机构列表
export function orgList (params) {
  return postBodyRequest('/cs/org/list',params)
}
// 员工职位列表
export function positiontype () {
  return getRequest('/cs/employee/position/type')
}
// 员工设置 在职-离职
export function employeeQuit (id) {
  return getRequest(`/cs/employee/quit?id=${id}`)
}
// 获取员工所属权限
export function employeeData (userId) {
  return getRequest(`/cs/employee/${userId}/data`)
}
// 更新员工的数据权限 个人-部门-全部
export function employeeAuth(userId,params) {
  return postBodyRequest(`/cs/employee/${userId}/data/auth`, params)
}
// 更新员工所属角色-选定-取消选定
export function employeeDataRole (userId,params) {
  return postBodyRequest(`/cs/employee/${userId}/data/role`, params)
}
// 获取员工所属角色
export function employeeRole (userId) {
  return getRequest(`/cs/employee/${userId}/role`)
}
// 角色分页
export function rolePage (params) {
  return postBodyRequest('/cs/role/page', params) 
}
