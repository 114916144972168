<template>
<div>
  <a-modal :width="width" :centered="centered"  :visible="visible" :title="title" @ok="handleOk" @cancel="cancel" :footer="null">
      <slot></slot>
      <div class="flex ju-end " v-if="footer&&!isTips">
        <ly-button type="default" class="m-r1" @click="cancel">取消</ly-button>
        <a-button :loading="loading" type="primary" @click="handleOk" >确定</a-button>
      </div>
      <div class="flex ju-end " v-if="footer&&isTips">
        <ly-button type="cyan" class="m-r1" @click="cancel">取消</ly-button>
        <ly-button   :loading="loading" type="error" @click="handleOk" >{{ delText }}</ly-button>
      </div>
    </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  title:{
    type:String,
    default:'Basic Modal'
  },
  visible:{
    type:Boolean,
    default:false
  },
  footer:{
    type:Boolean, 
    default:true
  },
  width:{
    type:String,
    default:'25%'
  },
  isTips:{
    type:Boolean,
    default:false
  },
  delText:{
    type:String,
    default:'删除'
  },
  loading:{
    type:Boolean,
    default:false
  },
  centered:{
    type:Boolean,
    default:false
  }
})
const emit = defineEmits(['update:visible','cancel','handleOk'])
const cancel = () => {
  emit('update:visible',false)
  emit('cancel')
}
const handleOk = () => {
  emit('handleOk')
}

</script>

<style lang="less" scoped>

</style>