<template>
  <div>
    <div style="height:10px"></div>
    <div class="tableBox ">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="应收应付" force-render>
            <DataExport></DataExport>
        </a-tab-pane>
      </a-tabs>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import DataExport from './components/dataExport.vue'

const activeKey = ref('1')
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px;
  background: #ffffff;
  height: calc(100vh - 150px);
}
</style>