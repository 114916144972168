<template>
  <div>
    <a-button type="primary" @click="visible = true">+&nbsp;新增账户</a-button>
    <div class="m-t2">
      <ly-table :columns="columns" ref="tableRef" rowKey="id" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #add="{ record }">
          <a-popconfirm title="你确认删除吗?" ok-text="确定" cancel-text="取消" @confirm="delAdd(record)" >
            <span class="m-l3 color-R cur-p" >删除</span>
          </a-popconfirm>
        </template>
      </ly-table>
    </div>
    <a-modal v-model:visible="visible" title="新增收款账户" :footer="false" destroyOnClose>
      <ly-formList v-if="visible" :fromList="fromList" ref="fromListRef" @cancel="visible = false" @getVal="getVal"></ly-formList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { collectionAdd, collectionList, collectionDel } from '@/api/system/logo'
import { message } from 'ant-design-vue';

const fromListRef = ref(null)
const visible = ref(false)
const loading = ref(false)
const data = ref([])
const getVal = (e) => {
  fromListRef.value.fileLoading = true
  collectionAdd(e).then( res => {
    if(res.code !== 10000) return
    message.success('添加成功')
    visible.value = false
    reqCollectionList()
  }).finally(() => {
    if(fromListRef.value) {
      fromListRef.value.fileLoading = false
    }
  })
}
const reqCollectionList = () => {
  loading.value = true
  collectionList({}).then( res => {
    if(res.code !== 10000) return
    data.value = res.data
  }).finally(() => {
    loading.value = false
  })
}
const delAdd = (e) => {
  collectionDel(e.id).then( res => {
    if(res.code !== 10000) return
    message.success('删除成功')
    reqCollectionList()
  })
}
onMounted(() => {
  reqCollectionList()
})
const columns = ref([
  {
    title: '收款账户名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
  },
])
const fromList = ref([
  {
    label: '收款账户名称',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入收款账户名称',
        trigger: 'blur'
      },
    ]
  },
])
</script>

<style lang="less" scoped>
</style>