import { getRequest, postBodyRequest, fileRealUpload, postBodyRequestSpecial,putRequest } from '@/utils/axios'

// 图片上传
export const fileUpload = params => {
  return fileRealUpload(`/cs/file/upload/${Math.floor(Math.random() * 1000)}`, params)
}
//预览图片
export function abnormalImgs (params) {
  return postBodyRequest('/cs/file/view-files', params)
}
//车辆列表
export function carBrandTree (params) {
  return getRequest('/cs/system/car/tree', params)
}
// 所有品牌
export function carBrand () {
  return getRequest('/cs/system/car/brand') 
}
//城市列表
export function getCityList (params) {
  return getRequest('/cs/system/city', params)
}
//车辆列表
export function getCarTree (params) {
  return getRequest('/cs/system/car/tree', params)
}
// 员工list
export function employeeList (params) {
  return postBodyRequest('/cs/employee/list', params) 
}
// 业务对接人list
export function businessList (params) {
  return postBodyRequest('/cs/docking/business-docking/list', params)
}