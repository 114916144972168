import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 分页
export function page (params) {
  return postBodyRequest('/cs/role/page', params) 
}
// 角色新增
export function roleAdd (params) {
  return postBodyRequest('/cs/role/add', params) 
}
// 角色编辑
export function roleEdit (params) {
  return postBodyRequest('/cs/role/edit', params) 
}

// 角色的资源
export function roleResource (roleId,params) {
  return getRequest(`/cs/role/${roleId}/resource`,params) 
}
// 角色授权菜单
export function grantResource (params) {
  return postBodyRequest('/cs/role/grant/resource', params) 
}
// 资源树形
export function resourceTree(params) {
  return getRequest('/cs/role/resource/tree', params) 
}