<template>
  <a-button
    :type="customType"
    :class="customClass"
    :disabled="disabled"
    :loading="loading"
  >
  <template #icon><slot name="icon"></slot></template>   
   <slot />
  </a-button>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'CButtonIndex',
  props: {
    type: { type: String, default: '' },
    size: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    loading:{type: Boolean, default: false}
  },
  setup (props) {
    const customClass = ref('c-button-primary')
    const customType = ref('')
    watch(() => props.type, (v) => {
      switch (v) {
      case 'warning':
        customClass.value = 'c-button-warning'
        customType.value = 'default'
        break
      case 'error':
        customClass.value = 'c-button-error'
        customType.value = 'default'
        break
      case 'success':
        customClass.value = 'c-button-success'
        customType.value = 'default'
        break
      case 'primary':
        customClass.value = 'c-button-primary'
        customType.value = 'primary'
        break
      case 'cyan':
        customClass.value = 'c-button-cyan'
        customType.value = 'default'
        break
      case 'black':
        customClass.value = 'c-button-black'
        customType.value = 'default'
        break
      case 'purple':
        customClass.value = 'c-button-purple'
        customType.value = 'default'
        break
      case 'text':
        customClass.value = ''
        customType.value = 'text'
        break
      case 'link':
        customClass.value = 'c-button-link'
        customType.value = 'link'
        break
      case 'default':
       customClass.value = 'c-button-default'
        customType.value = 'default'
        break
      default:
        customClass.value = ''
        customType.value = 'default'
        break
      }
    }, { immediate: true })
  
    return {
      customClass,
      customType,
    }
  }
})
</script>
<style scoped>

.c-button-primary {
  color: #fff;
  background-color: #365DD2;
  border-color: #365DD2;
}
.c-button-primary:hover {
  color: #fff;
  background-color: #557AEC;
  border-color: #557AEC;
}
.c-button-primary:focus {
  color: #fff;
  background-color: #204AC4;
  border-color: #204AC4;
}
.c-button-primary[disabled], .c-button-primary[disabled]:hover, .c-button-primary[disabled]:focus, .c-button-primary[disabled]:active {
  color:#fff;
  background:#C5D3FF;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.c-button-link{
  color: #365DD2;
}
.c-button-default{
  color: #000000;
  border-color: #BFBFBF;
}
.c-button-default:hover{
  color: #365DD2;
  border-color: #365DD2;
}
.c-button-default:focus{
  color: #204AC4;
  border:1px solid #204AC4
}
.c-button-default[disabled], .c-button-default[disabled]:hover, .c-button-default[disabled]:focus, .c-button-default[disabled]:active {
  border: 1px solid #BFBFBF;
  background:#F3F3F3;
  color: #999999;

}

.c-button-warning {
  color: #fff;
  background-color: #E9941B;
  border-color: #E9941B;
}
.c-button-warning:hover {
  color: #fff;
  background-color: #E5A74F;
  border-color:#E5A74F;
}
.c-button-warning:focus {
  color: #fff;
  background-color: #D48310;
  border-color:#D48310;
}
.c-button-warning[disabled], .c-button-warning[disabled]:hover, .c-button-warning[disabled]:focus, .c-button-warning[disabled]:active {
  color:#fff;
  background:#FFE8C5;
  border-color: #FFE8C5;
  text-shadow: none;
  box-shadow: none;
}

.c-button-error {
  color: #fff;
  background-color: #DC363E;
  border-color: #DC363E;
}
.c-button-error:hover {
  color: #fff;
  background-color: #EB686E;
  border-color: #EB686E;
}
.c-button-error:focus {
  color: #fff;
  background-color: #D4121C;
  border-color: #D4121C;
}
.c-button-error[disabled], .c-button-error[disabled]:hover, .c-button-error[disabled]:focus, .c-button-error[disabled]:active {
  color:#fff;
  background:#FFD2D4;
  border-color: #FFD2D4;
  text-shadow: none;
  box-shadow: none;
}

.c-button-success {
  color: #fff;
  background-color: #00cc66;
  border-color: #00cc66;
}
.c-button-success:hover {
  color: #fff;
  background-color: #03e071;
  border-color: #00cc66;
}
.c-button-success[disabled], .c-button-success[disabled]:hover, .c-button-success[disabled]:focus, .c-button-success[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.c-button-cyan {
  color: #000;
  background: #EBEBEB;
  border-color: #EBEBEB;
}
.c-button-cyan:hover {
  color: #557AEC;
  background: #EBEBEB;
  border-color: #EBEBEB;

}
.c-button-cyan:focus {
  color: #204AC4;
  background: #D9D9D9;
  border-color: #D9D9D9;
}
.c-button-cyan[disabled], .c-button-cyan[disabled]:hover, .c-button-cyan[disabled]:focus, .c-button-cyan[disabled]:active {
  color:#999999;
  background: #F2F2F2;
  border-color: #F2F2F2;
  text-shadow: none;
  box-shadow: none;
}

.c-button-black {
  color: #fff;
  background-color: #131313;
  border-color: #131313;
}
.c-button-black:hover {
  color: #fff;
  background-color: #313131;
  border-color: #131313;
}
.c-button-black[disabled], .c-button-black[disabled]:hover, .c-button-black[disabled]:focus, .c-button-black[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.c-button-purple {
  color: #fff;
  background-color: #B500FE;
  border-color: #B500FE;
}
.c-button-purple:hover {
  color: #fff;
  background-color: #c951fa;
  border-color: #B500FE;
}
.c-button-purple[disabled], .c-button-purple[disabled]:hover, .c-button-purple[disabled]:focus, .c-button-purple[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
</style>