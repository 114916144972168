<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <a-button @click="placeOrder">下单</a-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #sort="{index}">
          {{ index+1 }}
        </template>
        <template #id="{ record }">
          <a @click="editAdd(record)">{{ record.id }}</a>
        </template>
        <template #startAddress="{ record }">
          <div>{{ record.startAddress }}</div>
          {{ record.endAddress }}
        </template>
        <template #vinNo="{ record }">
          <div v-if="record.vinNo">
            <div v-for="(item,index) in record.vinNo.split(',')" :key="index">
              {{ item }}
            </div>
          </div>
        </template>
        <template #orderStatus="{record }">
          <span :style="{color:colorList[record.orderStatus?.value]}">{{ record.orderStatus?.label }}</span>
        </template>
        <template #isInvoice="{ record }">
          <span v-if="record.isInvoice?.value === 1">发票&nbsp;</span>
          <span v-if="record.isRefund?.value === 1">返款&nbsp;</span>
          <span v-if="record.isNoticeReleaseCar?.value === 1">等通知放车</span>
        </template>
        <template #add="{ record }">
          <a @click="vehicleAdd(record)">车辆</a>
          <a class="m-l1" @click="settlementAdd(record)">结算</a>
          <a class="m-l1" v-if="record.isPeerCollection?.value === 1" @click="CollectionAdd(record)">代收码</a>
          <a-dropdown class="m-l1">
            <a class="ant-dropdown-link" @click.prevent>
              更多
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item v-if="record.orderStatus?.value === 0">
                  <a @click="addOrder(record.id,1)">推送</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="addOrder(record.id,2)">复制订单</a>
                </a-menu-item>
                <a-menu-item v-if="record.insuranceFileId">
                  <a @click="lookImg(record.insuranceFileId)">保单</a>
                </a-menu-item>
                <a-menu-item v-if="record.orderStatus?.value !== 0 && record.orderStatus?.value !== 7">
                  <a v-if="!record.isPeerCollection || record.isPeerCollection?.value === 0" @click="vehQRC(record)">支付码</a>
                </a-menu-item>
                <a-menu-item v-if="record.orderStatus?.value === 0">
                  <a @click="addOrder(record.id,3)">取消</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </ly-table>
      <!-- 车辆信息 -->
      <ly-modal v-model:visible="vehVisible" title="车辆" :footer="false" width="50%">
        <!-- <a-button @click="splitOrd" style="margin-bottom:5px">拆分订单</a-button>  -->
        <ly-table :columns="vehColumns" v-if="vehVisible" size="small" :data="vehData" @onChange="onChange" :rowSelectionShow="false">
          <template #brand="{ record }">
            {{ record.brand }} {{  record.model }}
          </template>
          <template #profitMonth="{ record }">
            {{ record.profitYear}}-{{ record.profitMonth }} 
          </template>
          <template #add="{ record }">
            <a class="m-r1" @click="vehQRC(record)">支付码</a>
            <a class="m-r1" @click="showModal = true,orderVehicleId=record.orderVehicleId">车辆照片({{record.picNum }})</a>
            <a @click="transportInfo(record)">运输信息</a>
          </template>
        </ly-table>
      </ly-modal>
      <a-modal v-model:visible="visibleTips" title="提示" okText="确认拆分" @Ok="saveSplit">
        <p style="color:#B30000">已选车辆{{selectedText}}将保留在当前订单</p>
        <p style="color:#B30000" class="m-t2">剩余车辆{{notSelectedText}}将生成新的订单</p>
      </a-modal>
      <!-- 结算信息 -->
      <ly-modal v-model:visible="setVisible" title="结算信息" width="50%" :footer="false">
        <ly-table :columns="setColumns" size="small" :data="settlementData">
          <template #add="{ record }">
            <a>附加费</a>
            <a class="m-l1 m-r1">交车</a>
            <a>生成支付码</a>
          </template>
        </ly-table>
      </ly-modal>
      <!-- 提示 -->
      <ly-modal v-model:visible="confirmVisible" title="系统提示" @handleOk="handleOk" delText="确定" :isTips="true">
        <p>{{ tips }}</p>
      </ly-modal>
    </div>
    <!-- 运输信息 -->
    <a-modal v-model:visible="transportVisible" width="70%" footer=""  title="运输信息"  >
     <ly-table :columns="transportColumns" size="small" :data="transportData">
      <template #sort="{index}">
        {{ index + 1 }}
      </template>
      <template #transportFee="{ record }">
         <a @click="changeFeeAdd(record)">{{ record.transportFee }}</a>
      </template>
      <template #startAddress="{ record }">
        {{ record.startAddress }}
        <div>{{ record.endAddress }}</div>
      </template>
     </ly-table>
    </a-modal>
     <!-- 修改价格 -->
     <ChangeFee v-model:visible="changeFeeVisible" @ok="changeFeeOk" />
    <!-- 车辆图片 -->
    <ly-imageModal v-model:showModal="showModal" @success="success" v-if="showModal" :orderVehicleId="orderVehicleId"></ly-imageModal>
    <!-- 支付码 -->
    <PayQRC ref="payQRCref" />

    <!-- 自定义图片预览 -->
    <lyPictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></lyPictureViewer>
    <!-- 付款码 -->
    <PayModal ref="payQRCrefModal" />
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { DownOutlined } from "@ant-design/icons-vue";
import { iptData, columns, fromList, vehColumns, setColumns,transportColumns } from './documentary.js'
import { transitionTimeYMD, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { orderPage, orderDelete, orderCopy, orderInfo, vehicleList, ordeSplitrVehicle, orderPush, orderpayList, transportDetail } from '@/api/order/order'
import { vehicleChangeFee } from '@/api/transport/vehicle'
import { cloneDeep } from 'lodash-es';
import PayQRC from '@/components/payQRC'
import ChangeFee from './componets/changeFee'
import PayModal from '@/views/transport/components/payModal'

const router = useRouter()
const loading = ref(false)
const vehVisible = ref(false)
const setVisible = ref(false)
const data = ref([])
const searchMsg = ref({})
const confirmVisible = ref(false)
const tips = ref('')
const addType = ref(0)
const addID = ref('')
const vehData = ref([])
const splitData = ref([])
const visibleTips = ref(false)
const selectedText = ref('')
const notSelectedText = ref('')
const orderId = ref('')
const settlementData = ref([])
const showModal = ref(false)
const orderVehicleId = ref('')
const payQRCref = ref(null)
const lookImgID = ref([])
const visibleImgPic = ref(false)
const transportVisible = ref(false)
const transportData = ref([])
const changeFeeVisible = ref(false)
const changeFeeItem = ref({})
const transportID = ref('')
const payQRCrefModal= ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const colorList = ref({
  0: '#d48310',
  2: '#396afc',
  9: '#099858',
  7: '#999999'
})

// 代收码
const CollectionAdd = (item) => {
  let obj = {
    startAddress: item.startAddress,
    endAddress: item.endAddress,
    taskId: item.taskId,
    orderId: item.id,
    payType: 1,
    vinNo:item.vinNo
  } 
  setTimeout(() => {
    payQRCrefModal.value.openWindow(obj)
  }, 10)
}
//修改价格
const changeFeeAdd = (record) => {
  changeFeeVisible.value = true
  changeFeeItem.value = record
}
const changeFeeOk = (e) => {
  let msg = {
    totvId:changeFeeItem.value.totvId,
    transportId:changeFeeItem.value.transTruckId
  }
  Object.assign(msg,e)
  vehicleChangeFee(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    changeFeeVisible.value = false
    transportInfo(transportID.value)
  })
}
const lookImg = (fileId) => {
  lookImgID.value = [fileId]
  visibleImgPic.value = true
}
//运输信息
const transportInfo = (record) => {
  transportID.value = record
  transportDetail(record.orderVehicleId).then(res => {
    if (res.code !== 10000) return
    transportData.value = res.data
    transportVisible.value = true
  })
}

// 单辆车的支付码
const vehQRC = (record) => {
  let obj = {
    startAddress: record.startAddress,
    endAddress: record.endAddress,
    frId: '1',
    orderId: record.orderId || record.id,
    payType: 1,
    ovId: record.orderVehicleId,
    vehicleList: record.vinNo
  }
  console.log(record);
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
// 搜索
const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e)
  searchMsg.value.pushStartTime = transitionTimeYMD(searchMsg.value.pushStartTime)
  searchMsg.value.pushEndTime = transitionTimeYMD(searchMsg.value.pushEndTime)
  pagination.value.current = 1
  reqOrderPage()
}
//重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqOrderPage()
}

//拆分订单
const saveSplit = () => {
  ordeSplitrVehicle({
    orderId: orderId.value,
    orderVehicleIds: splitData.value.map(item => item.orderVehicleId)
  }).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    visibleTips.value = false
    vehVisible.value = false
    reqOrderPage()
  })
}
const splitOrd = () => {
  if (splitData.value.length === 0) {
    message.warn('请选择拆分的车辆')
    return
  }
  if (vehData.value.length === splitData.value.length) {
    message.warn('你已选择全部车辆，不能拆分')
    return
  }
  selectedText.value = ''
  notSelectedText.value = ''
  let notList = []
  vehData.value.forEach((item) => {
    splitData.value.forEach(opt => {
      if (item.orderVehicleId !== opt.orderVehicleId) {
        notList.push(item)
      }
    })
  })
  splitData.value.forEach(opt => {
    selectedText.value += `【${opt.vinNo}】`
  })
  notList.forEach(opt => {
    notSelectedText.value += `【${opt.vinNo}】`
  })
  visibleTips.value = true
}
const onChange = (e, v) => {
  splitData.value = v
}
const handleOk = () => {
  let req = null
  if (addType.value === 1) {
    req = orderPush
  }
  if (addType.value === 2) {
    req = orderCopy
  }
  else if (addType.value === 3) {
    req = orderDelete
  }
  req(addID.value).then(res => {
    if (res.code !== 10000) return
    message.success('操作成功')
    confirmVisible.value = false
    reqOrderPage()
  })

}
let tipsList = {
  1: '您确定要推送该订单吗？',
  2: '您确定要复制该订单吗？',
  3: '您确定要取消该订单吗？'
}
const addOrder = async (id, type) => {
  if (type === 1) {
    orderInfo(id).then(res => {
      if (res.code !== 10000) return
      addID.value = id
      tips.value = tipsList[type]
      addType.value = type
      confirmVisible.value = true
    })
    return
  }
  addID.value = id
  tips.value = tipsList[type]
  addType.value = type
  confirmVisible.value = true
}
const vehicleAdd = (item) => {
  orderId.value = item.id
  reqVehicleList(item.id)
}
const settlementAdd = (item) => {
  orderpayList(item.id).then(res => {
    if (res.code !== 10000) return
    console.log(res.data);
    settlementData.value = res.data
    setVisible.value = true
  })

}
const pageChange = (e) => {
  pagination.value = e
  reqOrderPage()
}

//下单
const placeOrder = () => {
  router.push({ path: '/orderAdd' })
}
//编辑
const editAdd = (record) => {
  router.push({ path: '/orderDetail', query: { id: record.id } })
}

const reqOrderPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  orderPage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const success = () => {
  reqVehicleList(orderId.value)
}
const reqVehicleList = (orderId) => {
  vehicleList(orderId).then(res => {
    if (res.code !== 10000) return
    splitData.value = []
    vehData.value = res.data
    vehVisible.value = true
  })
}

onMounted(() => {
  reqOrderPage()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>